import {Button, Row} from 'components/general/Control'

const ControlButtons = ({
    onSave,
    isActive,
    onActivate,
    onUpgradeVersion,
    hideActivationButton,
    hideControls,
    hideDeleteButton,
    onDelete,
    topic,
}) => {
    if (hideControls) {
        return (
            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="delete_button"
                    color="primary"
                    type="submit"
                    onClick={onDelete}>
                    {`Delete ${topic}`}
                </Button>
                <Button
                    id="save_button"
                    color="primary"
                    type="submit"
                    onClick={onUpgradeVersion}>
                    Upgrade to Version 2
                </Button>
            </Row>
        )
    }

    return (
        <Row className="d-flex justify-content-center pt-4">
            {!hideDeleteButton && (
                <Button
                    id="delete_button"
                    color="primary"
                    type="submit"
                    onClick={onDelete}>
                    {`Delete ${topic}`}
                </Button>
            )}
            <Button
                id="save_button"
                color="primary"
                type="submit"
                onClick={onSave}>
                SAVE
            </Button>
            {!hideActivationButton && (
                <Button
                    id="activate_button"
                    color="secondary"
                    type="submit"
                    onClick={onActivate}>
                    {isActive ? 'DEACTIVATE' : 'ACTIVATE'}
                </Button>
            )}
        </Row>
    )
}
export default ControlButtons
