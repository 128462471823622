import {React, styled} from 'common'
import {Input} from 'components/general/Control'
import {useUrlParams} from 'services/hooks'

const DefaultColumnFilterV2 = ({
    column: {totalData, id = 'name'},
    visibility = true,
}) => {
    const [urlParams, setUrlParam] = useUrlParams()

    return (
        visibility && (
            <StyledInput
                id="search_filter"
                value={urlParams[id] || ''}
                style={{visibility: visibility ? 'visible' : 'hidden'}}
                setter={(v) => {
                    setUrlParam(id, v)
                    setUrlParam('page_number', 0)
                }}
                placeholder={`Search ${totalData} records...`}
            />
        )
    )
}

export default DefaultColumnFilterV2

const StyledInput = styled(Input)`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
