import {useEffect, useState} from 'react'
import {Col, FormGroup, Input, Label, Row} from 'reactstrap'
import {
    useCallback,
    useDispatch,
    useHistory,
    useParams,
    useMemo,
} from 'services/hooks.js'
import AppInput from 'components/general/Control/Input'
import MultiCheckboxSelection from 'components/general/Control/MultiCheckboxSelection.js'
import {Button} from 'components/general/Control/index.js'
import {
    useSaveDeviceMappingMutation,
    useGetPaginationGearSetupQuery,
    useGetDeviceMappingByIdQuery,
} from 'store/slices/gearSetup'
import {skipToken} from '@reduxjs/toolkit/dist/query'
import {
    getSuccessSaveMessageData,
    showAlertMessage,
} from '../../services/utils.js'
import GearSetupSteps from './GearSetupSteps.js'
import GearSetupDeviceInfoTag from './GearSetupDeviceInfoTag.js'

const EmptySteps = {step: '', title: '', description: '', image_url: ''}

const EmptyDeviceInfoTags = {title: '', icon: '', args: ''}

const DeviceMappingDetail = ({onError}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const isCreateNew = params?.id === 'new'

    const {data: deviceMappingData} = useGetDeviceMappingByIdQuery(
        isCreateNew ? skipToken : params?.id
    )

    const [saveDeviceMapping, {isSuccess}] = useSaveDeviceMappingMutation()

    const {data: gearSetupList} = useGetPaginationGearSetupQuery()

    const [updateDeviceMapping, setUpdateDeviceMapping] = useState({})

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    const onSave = (ev) => {
        ev?.preventDefault()

        saveDeviceMapping(updateDeviceMapping)
            .unwrap()
            .catch((err) => onError(err?.data?.message))
    }

    useEffect(() => {
        if (deviceMappingData) {
            const gearSteps = deviceMappingData?.stepsData || []
            setUpdateDeviceMapping({
                ...deviceMappingData,
                stepsData: gearSteps,
                deviceInfo: deviceMappingData?.deviceInfo || {},
            })
        }
    }, [deviceMappingData])

    useEffect(() => {
        if (isSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(
                updateDeviceMapping?.display_name
            )

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isSuccess, redirectToList, updateDeviceMapping])

    const setter = (field, value) => {
        setUpdateDeviceMapping((old) => ({...old, [field]: value}))
    }

    const stepSetter = (field, value, idx) => {
        const gearSteps = [...(updateDeviceMapping?.stepsData || [])]
        gearSteps[idx] = {...gearSteps[idx], [field]: value}

        setter('stepsData', gearSteps)
    }

    const deleteStepAction = (idx) => {
        const gearSteps = updateDeviceMapping?.stepsData.filter(
            (_, i) => i !== idx
        )
        setter('stepsData', gearSteps)
    }

    const addNewGearStep = () => {
        const gearSteps = [...(updateDeviceMapping?.stepsData || [])]
        gearSteps.push(EmptySteps)
        setter('stepsData', gearSteps)
    }

    const deviceInfoSetter = (field, value) => {
        const deviceInfo = {
            ...(updateDeviceMapping?.deviceInfo || {}),
            [field]: value,
        }

        setter('deviceInfo', deviceInfo)
    }

    const deviceInfoTagSetter = (field, value, idx) => {
        const deviceInfo = {...(updateDeviceMapping?.deviceInfo || {})}
        const deviceInfoTags = [...(deviceInfo?.tags || [])]
        deviceInfoTags[idx] = {...deviceInfoTags[idx], [field]: value}
        deviceInfo.tags = deviceInfoTags
        setter('deviceInfo', deviceInfo)
    }

    const deleteDeviceInfoTagAction = (idx) => {
        const deviceInfo = {...(updateDeviceMapping?.deviceInfo || {})}
        deviceInfo.tags = [...(deviceInfo?.tags || [])]?.filter(
            (_, i) => i !== idx
        )
        setter('deviceInfo', deviceInfo)
    }

    const addNewDeviceInfoTag = () => {
        const deviceInfo = {...(updateDeviceMapping?.deviceInfo || {})}
        const deviceInfoTags = [...(deviceInfo?.tags || [])]
        deviceInfoTags.push(EmptyDeviceInfoTags)
        deviceInfo.tags = deviceInfoTags
        setter('deviceInfo', deviceInfo)
    }

    const checkBoxDevicesOpts = useMemo(() => {
        return (
            gearSetupList?.data?.map((v) => ({
                label: v.name,
                value: v.deviceId,
            })) || []
        )
    }, [gearSetupList])

    return (
        <div className="px-3">
            <Row>
                <Col xs="12">
                    <FormGroup>
                        <Label>Display Name</Label>
                        <AppInput
                            name="display_name"
                            value={updateDeviceMapping.display_name}
                            setter={(v) => setter('display_name', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>IMAGE URL</Label>
                        <AppInput
                            name="image"
                            value={updateDeviceMapping?.image}
                            setter={(v) => setter('image', v)}
                        />
                    </FormGroup>
                </Col>

                <Col xs="12">
                    <FormGroup>
                        <MultiCheckboxSelection
                            label="Devices"
                            options={checkBoxDevicesOpts}
                            selected={updateDeviceMapping.devices || []}
                            onChange={(v) => setter('devices', v)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row className="border border-default rounded-lg py-2 mt-2">
                {/*DEVICE INFO*/}
                <Col sm="12">
                    <Label>DEVICE INFO</Label>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>TITLE</Label>
                        <AppInput
                            name="title"
                            value={updateDeviceMapping?.deviceInfo?.title}
                            setter={(v) => deviceInfoSetter('title', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>HEADING</Label>
                        <AppInput
                            name="heading"
                            value={updateDeviceMapping?.deviceInfo?.heading}
                            setter={(v) => deviceInfoSetter('heading', v)}
                        />
                    </FormGroup>
                </Col>
                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>SUBHEADING</Label>
                        <AppInput
                            name="subHeading"
                            value={updateDeviceMapping?.deviceInfo?.subHeading}
                            setter={(v) => deviceInfoSetter('subHeading', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>STARS SCORE</Label>
                        <AppInput
                            name="starsScore"
                            value={updateDeviceMapping?.deviceInfo?.starsScore}
                            setter={(v) => deviceInfoSetter('starsScore', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>REVIEWS</Label>
                        <AppInput
                            name="reviews"
                            value={updateDeviceMapping?.deviceInfo?.reviews}
                            setter={(v) => deviceInfoSetter('reviews', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>BUY URL</Label>
                        <AppInput
                            name="linkBuy"
                            value={updateDeviceMapping?.deviceInfo?.linkBuy}
                            setter={(v) => deviceInfoSetter('linkBuy', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="12">
                    <FormGroup>
                        <Label for="description">DESCRIPTION</Label>
                        <Input
                            name="description"
                            type="textarea"
                            value={updateDeviceMapping?.deviceInfo?.description}
                            className="px-2"
                            onChange={(e) =>
                                deviceInfoSetter('description', e.target.value)
                            }
                        />
                    </FormGroup>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>NUMBER GUIDE WORKOUT</Label>
                        <AppInput
                            name="numberGuideWorkout"
                            value={
                                updateDeviceMapping?.deviceInfo
                                    ?.numberGuideWorkout
                            }
                            setter={(v) =>
                                deviceInfoSetter('numberGuideWorkout', v)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>LINK GUIDE WORKOUT</Label>
                        <AppInput
                            name="linkGuideWorkout"
                            value={
                                updateDeviceMapping?.deviceInfo
                                    ?.linkGuideWorkout
                            }
                            setter={(v) =>
                                deviceInfoSetter('linkGuideWorkout', v)
                            }
                        />
                    </FormGroup>
                </Col>

                <Col sm="12" md="6">
                    <FormGroup>
                        <Label>VIDEO URL</Label>
                        <AppInput
                            name="videoUrl"
                            value={updateDeviceMapping?.deviceInfo?.videoUrl}
                            setter={(v) => deviceInfoSetter('videoUrl', v)}
                        />
                    </FormGroup>
                </Col>

                {/*DEVICE TAGS*/}
                <Col sm="12">
                    <Row>
                        <Col>
                            <Label>DEVICE TAGS</Label>
                        </Col>
                        <Col sm="12">
                            <Button
                                size="sm"
                                type="button"
                                onClick={addNewDeviceInfoTag}>
                                ADD TAG
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12">
                    {updateDeviceMapping &&
                        updateDeviceMapping?.deviceInfo?.tags?.map(
                            (deviceInfoTag, idx) => (
                                <GearSetupDeviceInfoTag
                                    key={idx}
                                    deviceInfoTag={deviceInfoTag}
                                    setter={(f, v) =>
                                        deviceInfoTagSetter(f, v, idx)
                                    }
                                    deleteAction={() =>
                                        deleteDeviceInfoTagAction(idx)
                                    }
                                />
                            )
                        )}
                </Col>
            </Row>

            {/* STEPS */}
            <Row className="border border-default rounded-lg py-2 mt-2">
                <Col>
                    <Label>GEAR STEP</Label>
                </Col>
                <Col sm="12">
                    <Button size="sm" type="button" onClick={addNewGearStep}>
                        ADD STEP
                    </Button>
                </Col>

                <Col sm="12">
                    {updateDeviceMapping &&
                        updateDeviceMapping?.stepsData?.map((step, index) => (
                            <GearSetupSteps
                                key={index}
                                gearStep={step}
                                setter={(f, v) => stepSetter(f, v, index)}
                                deleteAction={() => deleteStepAction(index)}
                            />
                        ))}
                </Col>
            </Row>

            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="save_button"
                    color="primary"
                    type="submit"
                    onClick={onSave}>
                    {isCreateNew ? 'CREATE' : 'UPDATE'} DEVICE MAPPING
                </Button>
            </Row>
        </div>
    )
}
export default DeviceMappingDetail
