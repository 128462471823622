import {createApi} from '@reduxjs/toolkit/query/react'
import {axiosBaseQuery} from 'store/common'

const transformResponseFromData = (response) => response.data

const provideTags = (tagName, data) =>
    data
        ? [
              ...data.map(({id}) => ({type: tagName, id})),
              {type: tagName, id: 'LIST'},
          ]
        : [{type: tagName, id: 'LIST'}]

const provideTag = (tagName, data, tagField) =>
    data ? {type: tagName, id: data[tagField]} : {}

const gearSetupApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'gearSetup',
    tagTypes: [
        'GearSetupPagination',
        'GearSetup',
        'DeviceMappings',
        'DeviceMapping',
    ],
    endpoints: (build) => ({
        getPaginationGearSetup: build.query({
            query: (params) => ({
                url: 'admin/gear-setup',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) =>
                provideTags('GearSetupPagination', data?.data),
        }),
        getGearSetup: build.query({
            query: (device) => ({
                url: `admin/gear-setup/${device}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('GearSetup', data, '_id')],
        }),
        getDeviceMappings: build.query({
            query: (params) => ({
                url: 'admin/gear-setup/mapping',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('DeviceMappings', data?.data),
        }),
        getDeviceMappingById: build.query({
            query: (id) => ({
                url: `admin/gear-setup/mapping/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('DeviceMapping', data, '_id')],
        }),
        saveDeviceMapping: build.mutation({
            query: (data) => ({
                url: `admin/gear-setup/mapping`,
                method: 'POST',
                body: data,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('DeviceMapping', data, '_id')],
            invalidatesTags: (_, __, data) => [
                {type: 'DeviceMappings', id: 'LIST'},
                {type: 'DeviceMapping', id: data?._id},
            ],
        }),
        saveGearSetup: build.mutation({
            query: (data) => ({
                url: 'admin/gear-setup',
                method: 'POST',
                body: data,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('GearSetup', data, '_id')],
            invalidatesTags: (_, __, data) => [
                {type: 'GearSetupPagination', id: 'LIST'},
                {type: 'GearSetup', id: data?._id},
            ],
        }),
    }),
})

export default gearSetupApi

export const {
    useGetPaginationGearSetupQuery,
    useGetGearSetupQuery,
    useSaveGearSetupMutation,
    useGetDeviceMappingsQuery,
    useGetDeviceMappingByIdQuery,
    useSaveDeviceMappingMutation,
} = gearSetupApi
