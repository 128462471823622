import Dashboard from 'screens/Dashboard.js'

// Users
import Users from 'screens/Users.js'
import User from 'screens/User.js'
import UsersInDeletion from 'screens/UsersInDeletion'

// // Exercises
import Exercises from 'screens/Exercises.js'
import Exercise from 'screens/Exercise'

// // Workouts
import Workouts from 'screens/Workouts.js'
import Workout from 'screens/Workout'

// // Programs
import Programs from 'screens/Programs.js'
import Program from 'screens/Program'

// //Trainers
import Trainers from 'screens/Trainers.js'
import Trainer from 'screens/Trainer.js'

// //Catalog
import CatalogsPages from 'screens/CatalogsPages.js'
import CatalogPage from 'screens/CatalogPage.js'

// //Label
import Lables from 'screens/Labels.js'
import Lable from 'screens/Label.js'

// //Reseller
import Resellers from 'screens/Resellers.js'
import Reseller from 'screens/Reseller.js'

// //Reseller
import Coupons from 'screens/Coupons.js'
import Coupon from 'screens/Coupon.js'
import Routines from 'screens/Routines'
import Routine from 'screens/Routine'
import RoutineGears from 'screens/RoutineGears'
import ReportWeekly from 'screens/ReportWeekly'
import AccountList from 'screens/AccountList'
import listAlt from 'assets/img/list-alt.svg'
import Configs from 'screens/Configs'
import Config from 'screens/Config'

// Gear Setup
import GearSetups from 'screens/GearSetups.js'
import GearSetup from 'screens/GearSetup.js'
import DeviceMapping from '../screens/DeviceMapping.js'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        icon: 'tim-icons icon-chart-pie-36',
        component: Dashboard,
        layout: '/admin',
        title: 'Dashboard',
    },
    {
        path: '/account-list/',
        name: 'Accounts',
        imgIcon: listAlt,
        mini: 'UP',
        component: AccountList,
        layout: '/admin',
        title: 'Accounts',
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        icon: 'tim-icons icon-single-02',
        layout: '/admin',
        title: 'Users',
    },
    {
        path: '/user-profile/',
        name: 'User Profile',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: User,
        layout: '/admin',
        title: 'User Profile',
    },
    {
        path: '/users-in-deletion/',
        name: 'Users in deletion',
        hidden: true,
        mini: 'UP',
        component: UsersInDeletion,
        layout: '/admin',
        title: 'User Profile',
    },
    {
        path: '/exercises/',
        name: 'Exercises List',
        icon: 'tim-icons icon-user-run',
        mini: 'UP',
        component: Exercises,
        layout: '/admin',
        title: 'Exercises List',
    },
    {
        path: '/exercise/',
        name: 'Exercise',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: Exercise,
        layout: '/admin',
        title: 'Exercise Details',
    },

    {
        path: '/workouts/',
        name: 'Workouts List',
        icon: 'tim-icons icon-spaceship',
        mini: 'UP',
        component: Workouts,
        layout: '/admin',
        title: 'Workouts List',
    },
    {
        path: '/workout/',
        name: 'Workout',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: Workout,
        layout: '/admin',
        title: 'Workout Details',
    },

    {
        path: '/programs/',
        name: 'Programs List',
        icon: 'tim-icons icon-vector',
        mini: 'UP',
        component: Programs,
        layout: '/admin',
        title: 'Programs List',
    },
    {
        path: '/program/',
        name: 'Program',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: Program,
        layout: '/admin',
        title: 'Program Details',
    },

    {
        // routine gear list
        path: '/routines/',
        specific: ':device_type',
        name: 'Daily Habit Routine',
        hidden: true,
        component: Routines,
        layout: '/admin',
        title: 'Daily Habit Routine',
    },
    {
        // routine by gear list
        path: '/routines/',
        name: 'Daily Habit Routine',
        icon: 'tim-icons icon-calendar-60',
        mini: 'UP',
        component: RoutineGears,
        layout: '/admin',
        title: 'Daily Habit Routine',
    },
    {
        path: '/routine/new/',
        name: 'Create Daily Habit Routine',
        specific: ':device_type',
        hidden: true,
        mini: 'UP',
        component: Routine,
        layout: '/admin',
        title: 'Create Daily Habit Routine',
    },
    {
        path: '/routine/modify/',
        specific: ':device_type',
        name: 'Modify Daily Habit Routine',
        hidden: true,
        mini: 'UP',
        component: Routine,
        layout: '/admin',
        title: 'Modify Daily Habit Routine',
    },

    {
        path: '/trainer/',
        specific: ':id',
        name: 'Trainer',
        hidden: true,
        mini: 'UP',
        component: Trainer,
        layout: '/admin',
        title: 'Trainer Details',
    },
    {
        path: '/trainers/',
        name: 'Trainers List',
        icon: 'tim-icons icon-user-run',
        mini: 'UP',
        component: Trainers,
        layout: '/admin',
        title: 'Trainers List',
    },
    {
        path: '/labels/',
        name: 'Labels List',
        icon: 'tim-icons icon-notes',
        mini: 'UP',
        component: Lables,
        layout: '/admin',
        title: 'Labels List',
    },
    {
        path: '/label/',
        name: 'Label',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: Lable,
        layout: '/admin',
        title: 'Label Details',
    },
    {
        path: '/resellers/',
        name: 'Resellers List',
        icon: 'tim-icons icon-single-02',
        mini: 'UP',
        component: Resellers,
        layout: '/admin',
        title: 'Resellers List',
    },
    {
        path: '/reseller/',
        name: 'Reseller',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: Reseller,
        layout: '/admin',
        title: 'Reseller Details',
    },
    {
        path: '/coupons/',
        name: 'Coupons List',
        icon: 'tim-icons icon-notes',
        mini: 'UP',
        component: Coupons,
        layout: '/admin',
        title: 'Coupons List',
    },
    {
        path: '/coupon/',
        name: 'Coupon',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: Coupon,
        layout: '/admin',
        title: 'Coupon Details',
    },
    {
        path: '/catalog-pages/',
        name: 'Catalog Page',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: CatalogPage,
        layout: '/admin',
        title: 'Catalog Page Details',
    },
    {
        path: '/catalog-pages/',
        name: 'Catalog Pages List',
        icon: 'tim-icons icon-chart-pie-36',
        mini: 'UP',
        component: CatalogsPages,
        layout: '/admin',
        title: 'Catalog Pages',
    },

    {
        path: '/report-weekly/',
        name: 'Overview Report Weekly',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: ReportWeekly,
        layout: '/admin',
        title: 'Report Pages',
    },

    {
        path: '/configs/',
        name: 'Global Config',
        icon: 'tim-icons icon-settings-gear-63',
        mini: 'UP',
        component: Configs,
        layout: '/admin',
        title: 'Config List',
    },
    {
        path: '/config/',
        name: 'Config',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: Config,
        layout: '/admin',
        title: 'Config Details',
    },

    {
        path: '/device-mapping/',
        name: 'Device Mapping',
        specific: ':id',
        hidden: true,
        mini: 'UP',
        component: DeviceMapping,
        layout: '/admin',
        title: 'Gear Setup Details',
    },
    {
        path: '/gear-setups/',
        name: 'Gear Setups',
        icon: 'tim-icons icon-settings',
        mini: 'UP',
        component: GearSetups,
        layout: '/admin',
        title: 'Gear Setup List',
    },
    {
        path: '/gear-setup/',
        name: 'Gear Setup',
        specific: ':device',
        hidden: true,
        mini: 'UP',
        component: GearSetup,
        layout: '/admin',
        title: 'Gear Setup Details',
    },
]

export default routes
