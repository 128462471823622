import {Col, FormGroup, Input, Label, Row} from 'reactstrap'
import {Button} from 'components/general/Control'
import AppInput from 'components/general/Control/Input'

/**
 *
 * @param {{title: string, description: string, image_url: string} | T} gearStep
 * @param {(field: string, value: string) => {}} setter
 * @param deleteAction
 * @returns {JSX.Element}
 * @constructor
 */
const GearSetupSteps = ({gearStep, setter, deleteAction}) => {
    return (
        <Row className="py-2">
            <Col sm="12" md="6">
                <FormGroup>
                    <Label>STEP</Label>
                    <AppInput
                        type="number"
                        value={gearStep.step}
                        setter={(v) => setter('step', v)}
                    />
                </FormGroup>
            </Col>
            <Col sm="12" md="6">
                <FormGroup>
                    <Label>TITLE</Label>
                    <AppInput
                        value={gearStep.title}
                        setter={(v) => setter('title', v)}
                    />
                </FormGroup>
            </Col>
            <Col sm="12" md="6">
                <FormGroup>
                    <Label>DESCRIPTION</Label>
                    <Input
                        type="textarea"
                        value={gearStep.description}
                        className="px-2"
                        onChange={(e) => setter('description', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col sm="12" md="6">
                <FormGroup>
                    <Label>IMAGE URL</Label>
                    <AppInput
                        value={gearStep.image}
                        setter={(v) => setter('image', v)}
                    />
                </FormGroup>
            </Col>
            <Col sm="6">
                <Label>ACTION</Label>
                <br />
                <Button size="sm" type="button" onClick={deleteAction}>
                    Delete Step
                </Button>
            </Col>
        </Row>
    )
}
export default GearSetupSteps
