import {useMemo, useUrlParams} from 'services/hooks'
import {Page} from 'components/general/Page'
import {TableWrapperV2} from 'components/general/Display/TableV2'
import {
    useGetDeviceMappingsQuery,
    useGetPaginationGearSetupQuery,
} from '../store/slices/gearSetup'

const columns = [
    {
        Header: 'Device Name',
        accessor: 'name',
    },
    {
        Header: 'Device ID',
        accessor: 'deviceId',
    },
]

const columns2 = [
    {
        Header: 'Display Name',
        accessor: 'display_name',
    },
    {
        Header: 'Devices',
        accessor: 'devices',
    },
]

const GearSetups = () => {
    const [urlParams] = useUrlParams()
    const {
        page_number = 0,
        page_size = 10,
        sortBy = 'device',
        sortType = 'asc',
    } = urlParams

    const gearSetups = useGetPaginationGearSetupQuery({
        page: page_number,
        limit: page_size,
        sortBy,
        sortType,
    })

    const dataGearSetup = useMemo(() => {
        return gearSetups?.data?.data?.map((prop, key) => ({
            id: prop._id,
            _id: prop._id,
            deviceId: prop?.deviceId,
            name: prop?.name,
            pathname: `/admin/gear-setup/${prop.deviceId}`,
        }))
    }, [gearSetups?.data?.data])

    const deviceMapping = useGetDeviceMappingsQuery({
        page: 0,
        limit: 100,
        sortBy: 'display_name',
        sortType: 'asc',
    })

    const dataDeviceMapping = useMemo(() => {
        return deviceMapping?.data?.data?.map((prop, key) => ({
            id: key,
            _id: prop?._id,
            display_name: prop?.display_name,
            devices: [...(prop?.devices || [])].join(', '),
            pathname: `/admin/device-mapping/${prop._id}`,
        }))
    }, [deviceMapping])

    return (
        <Page>
            <TableWrapperV2
                title="Gear Setups"
                isFetching={gearSetups?.isLoading}
                createNew={'/admin/gear-setup/new'}
                data={dataGearSetup}
                metadata={gearSetups?.data?.metadata}
                columns={columns}
            />

            <TableWrapperV2
                title="Device Mapping"
                isFetching={deviceMapping?.isLoading}
                createNew={'/admin/device-mapping/new'}
                data={dataDeviceMapping}
                metadata={deviceMapping?.data?.metadata}
                disablePagination
                columns={columns2}
            />
        </Page>
    )
}
export default GearSetups
