import {Col, Label} from 'reactstrap'
import AppInput from 'components/general/Control/Input'
import {getIsInvalidNumber} from 'services/utils'

const DurationWeek = ({setter, durationWeek}) => {
    const handleChange = (v) => {
        if (getIsInvalidNumber(v)) {
            return
        }
        setter(v)
    }
    return (
        <Col>
            <Label className="text-uppercase" for="duration_week">
                Program Duration in Week
            </Label>
            <AppInput
                pattern="\d*"
                inputMode="numeric"
                value={durationWeek}
                setter={handleChange}
                disableAutoComplete
                name={'duration_week'}
            />
        </Col>
    )
}

export default DurationWeek
