/* eslint-disable no-unused-vars */
import React from 'react'
import {
    ExercisePurpose,
    PostureSelection,
    ExerciseEquipment,
    MuscleGroupSelection,
    TrainerGenderSelection,
} from 'components/Exercise/Details'
import {NameAndDisplayName, DifficultySelection} from 'components/Details'
import BodyRegion from './BodyRegion'
import {Row} from 'react-bootstrap'

const GeneralContent = ({
    updatedExercise,
    showDraftError,
    showNameError,
    updateExerciseField,
    showError,
}) => {
    const {
        name,
        display_name,
        trainer_gender,
        purpose,
        posture,
        difficulty,
        exercise_type,
        muscle_group,
        body_region,
    } = updatedExercise || {}

    return (
        <>
            <NameAndDisplayName
                className={'px-3'}
                label={'EXERCISE'}
                name={name || ''}
                setName={(value) => updateExerciseField('name', value)}
                displayName={display_name || ''}
                setDisplayName={(value) =>
                    updateExerciseField('display_name', value)
                }
                nameError={showNameError}
                showError={showError || showDraftError}
                mandatoryDisplayName
            />
            <Row className="justify-content-between px-3">
                <DifficultySelection
                    difficulty={difficulty}
                    setDifficulty={(value) =>
                        updateExerciseField('difficulty', value)
                    }
                    showError={showError}
                />
                <ExercisePurpose
                    type={purpose}
                    setType={(value) => updateExerciseField('purpose', value)}
                    showError={showError}
                />
            </Row>
            <Row className="justify-content-between px-3">
                <BodyRegion
                    showError={showError}
                    region={body_region}
                    setRegion={(value) =>
                        updateExerciseField('body_region', value)
                    }
                />
                <MuscleGroupSelection
                    muscleGroup={muscle_group}
                    setMuscleGroup={(value) =>
                        updateExerciseField('muscle_group', value)
                    }
                    showError={showError}
                />
            </Row>
            <Row className="justify-content-between px-3">
                <PostureSelection
                    posture={posture}
                    setPosture={(value) =>
                        updateExerciseField('posture', value)
                    }
                    showError={showError}
                />
            </Row>

            <ExerciseEquipment
                type={exercise_type}
                setType={(value) => updateExerciseField('exercise_type', value)}
                showError={showError}
            />
            <TrainerGenderSelection
                trainerGender={trainer_gender}
                setTrainerGender={(value) =>
                    updateExerciseField('trainer_gender', value)
                }
                showError={showError}
            />
        </>
    )
}
export default GeneralContent
