import AppInput from 'components/general/Control/Input'
import React from 'react'
import {Col, Label} from 'reactstrap'
import {getIsInvalidNumber} from 'services/utils'

const WorkoutDifficulty = ({difficulty, setDifficulty, showError}) => {
    const handleDifficulty = (value) => {
        if (getIsInvalidNumber(value)) {
            return
        }
        setDifficulty(value)
    }

    return (
        <Col md={6} className="px-0  pr-md-3">
            <Label>DIFFICULTY *</Label>
            <AppInput
                value={difficulty || 0}
                setter={handleDifficulty}
                showError={showError}
                disableAutoComplete
                name="Difficulty"
                placeholder="Enter Difficlty Value"
            />
        </Col>
    )
}

export default WorkoutDifficulty
