/* eslint-disable no-unused-vars */

import {useMemo} from 'react'
import styled from 'styled-components'
import {Button, Input} from 'reactstrap'
import {CloseButton, Image} from 'react-bootstrap'
import {Label} from 'components/general/Control'
import defaultImage from 'assets/img/image_placeholder.jpg'

const getImageTitle = (title, required) => (required ? title + ' *' : title)

const ImageUpload = ({
    imageFile,
    setImage,
    width,
    height,
    title,
    showError,
    required,
}) => {
    const handleImageUpload = (event) => {
        let reader = new FileReader()
        let file = event.target.files[0]
        reader.onloadend = () => {
            setImage({
                file: file,
                previewUrl: reader.result,
            })
        }

        if (file) reader.readAsDataURL(file)
    }

    const handleImageRemove = (ev) => {
        ev.preventDefault()
        if (setImage) {
            setImage({
                file: null,
                previewUrl: null,
            })
        }
    }

    const showErrorClass = useMemo(() => {
        if (showError && !imageFile.previewUrl) {
            return 'form-control border-danger'
        }
    }, [imageFile.previewUrl, showError])

    const buttonText = useMemo(() => {
        if (imageFile?.previewUrl) {
            return 'Change Image'
        } else {
            return 'Select Image'
        }
    }, [imageFile?.previewUrl])

    return (
        <Container className={`p-1  ${showErrorClass}`}>
            <Title>{getImageTitle(title, required)}</Title>

            <DeleteButtonContainer width={width}>
                {imageFile?.previewUrl && (
                    <DeleteButton onClick={handleImageRemove} />
                )}
                <InputContainer>
                    <ImageContainer height={height} width={width}>
                        <ImagePreview
                            isfile={imageFile.previewUrl}
                            alt={title || 'Main image'}
                            src={imageFile?.previewUrl || defaultImage}
                        />
                    </ImageContainer>
                    {!imageFile.previewUrl && (
                        <Input
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            style={{display: 'none'}}
                            onChange={handleImageUpload}
                        />
                    )}
                </InputContainer>
            </DeleteButtonContainer>

            <div className="mt-5">
                <Input
                    type="url"
                    placeholder={'https://cloudinary.com/somepath/image'}
                    value={imageFile?.previewUrl || ''}
                    onChange={(ev) => {
                        setImage({file: null, previewUrl: ev.target.value})
                    }}
                />
                <LabelWrapper>
                    <Input
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        style={{display: 'none'}}
                        onChange={handleImageUpload}
                    />
                    <InputButton tag="div" className="btn mb-0">
                        {buttonText}
                    </InputButton>
                </LabelWrapper>
            </div>
        </Container>
    )
}

export default ImageUpload

const Container = styled.div`
    width: 340px;
    height: 400px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    margin: 20px 10px;
    position: relative;
`

const LabelWrapper = styled(Label)`
    width: 100%;
`

const InputContainer = styled(LabelWrapper)`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
`

const DeleteButtonContainer = styled.div`
    width: ${({width}) => width || '325px'};
    height: 25px;
    z-index: 1000;
    top: 30px;

    position: absolute;
    align-self: center;
`

const DeleteButton = styled(CloseButton)`
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 3;
    &.close {
        background-color: lightgray;
        border-radius: 3px;
    }
`

const ImageContainer = styled.div`
    box-shadow: 0 0 18px 0px #00000017;

    height: ${({height}) => height || '255px'};
    width: ${({width}) => width || '325px'};
`

const ImagePreview = styled(Image)`
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: ${({isfile}) => (isfile ? 'default' : 'pointer')};
`

const Title = styled.p`
    font-size: 15px;
    font-weight: bold;
`

const InputButton = styled(Button)`
    font-size: 13px;
    font-weight: normal;
    width: 98%;
`
