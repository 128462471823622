import {React} from 'common'
import {Page} from 'components/general/Page'
import api from 'services/api'
import _ from 'lodash'
import {
    useGetPaginationExerciseQuery,
    useSaveExerciseMutation,
} from 'store/store'
import {
    useMemo,
    useState,
    useEffect,
    useOnSaveCSV,
    useUrlParams,
} from 'services/hooks'
import {SweetAlert} from 'components/general/Display'
import {Col} from 'components/general/Control'
import {
    SelectColumnFilterV2,
    TableLinkV2,
    TableWrapperV2,
} from 'components/general/Display/TableV2'
import {exerciseEquipment} from 'constant/exercise'
import {skipToken} from '@reduxjs/toolkit/dist/query'
import {useCallback} from 'react'

const columns = [
    {
        Header: 'Internal Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'App Display Name',
        accessor: 'display_name',
    },
    {
        Header: 'Difficulty',
        accessor: 'difficulty',
        Filter: SelectColumnFilterV2,
        isSortableIcon: true,
        filterOptions: ['All', 1, 2, 3, 4, 5],
    },
    {
        Header: 'Copy',
        accessor: 'copy',
        maxWidth: 70,
        isDownloadable: true,
    },
    {
        Header: 'Active',
        accessor: 'activeIcon',
        Filter: SelectColumnFilterV2,
        filterOptions: ['All', 'true', 'false'],
    },
    {
        Header: 'Equipment',
        accessor: 'equipment',
        Filter: SelectColumnFilterV2,
        filterOptions: ['All', ...exerciseEquipment.map((eq) => eq.value)],
    },
    {
        Header: 'New Version',
        accessor: 'newVersion',
        Filter: SelectColumnFilterV2,
        filterOptions: ['All', 'true', 'false'],
    },
]

const Exercises = () => {
    const [saveExercise] = useSaveExerciseMutation()

    const [urlParams] = useUrlParams()
    const {
        page_number = 0,
        page_size = 10,
        equipment = 'All',
        sortBy = 'name',
        sortType = 'asc',
        difficulty = 'All',
        activeIcon = 'All',
        newVersion = 'All',
        name: keywords = '',
    } = urlParams

    const [shouldFetch, setShouldFetch] = useState(true)
    const {data: exercises, refetch} = useGetPaginationExerciseQuery(
        shouldFetch
            ? {
                  page: page_number,
                  limit: page_size,
                  active: activeIcon,
                  keywords,
                  difficulty,
                  exerciseType: equipment,
                  newVersion,
                  language: 'en',
                  sortBy,
                  sortType,
              }
            : skipToken
    )
    const [exToCopy, setExToCopy] = useState(null)
    const alertRef = React.createRef()
    const [isLoading, onSaveCSV] = useOnSaveCSV()

    const onCopy = (exercise) => {
        let newEx = {
            ...exercise,
            id: null,
            active: false,
            deleted: false,
            name: exercise.name?.trim() + ' - Copy',
        }
        setExToCopy(newEx)
    }

    const saveExerciseAndRefetch = async (exercise) => {
        await saveExercise(exercise)
        refetch()
    }

    const createExerciseCopy = async () => {
        delete exToCopy.instructions
        delete exToCopy.muscle
        delete exToCopy.workout_id
        delete exToCopy.trainer

        saveExerciseAndRefetch({
            exercise: exToCopy,
            id: null,
        })

        return setExToCopy(null)
    }

    const data = useMemo(
        () =>
            exercises?.data?.map((prop, key) => {
                return {
                    id: key,
                    name: prop.name,
                    display_name: prop.display_name,
                    trainer: prop.trainer,
                    copy: (
                        <TableLinkV2
                            color="green"
                            icon={'tim-icons icon-single-copy-04'}
                            onClick={() => onCopy(prop)}
                            text="Copy"
                        />
                    ),
                    pathname: `/admin/exercise/${prop.id}`,
                    _id: prop.id,
                    difficulty: prop.difficulty ? String(prop.difficulty) : '-',
                    muscles: prop.muscles,
                    active: prop.active,
                    version: prop.version,
                    equipment: prop.exercise_type,
                    activeIcon: prop.active ? 'true' : 'false',
                    newVersion: prop.version > 1 ? 'true' : 'false',
                }
            }),
        [exercises?.data]
    )

    useEffect(() => {
        exToCopy && alertRef?.current()
    }, [exToCopy, alertRef])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedRefetch = useCallback(
        _.debounce(() => {
            setShouldFetch(true)
            refetch()
        }, 500),
        []
    )
    useEffect(() => {
        if (keywords) {
            setShouldFetch(false)
            debouncedRefetch()
        }
        return () => {
            debouncedRefetch.cancel()
        }
    }, [debouncedRefetch, keywords])

    return (
        <Page hideSidbar={false}>
            <TableWrapperV2
                title="Exercises"
                isFetching={isLoading}
                onSaveCSV={() => onSaveCSV(api.getExcerciseCSV(), 'exercises')}
                createNew={'/admin/exercise/new'}
                data={data}
                metadata={exercises?.metadata}
                columns={columns}
            />

            <Col>
                <SweetAlert
                    ref={alertRef}
                    title={'Copy Exercise'}
                    mainText={exToCopy?.name}
                    action="created"
                    deactivate={createExerciseCopy}
                    confirmBtnText="Copy exercise"
                    id={exToCopy?.id}
                />
            </Col>
        </Page>
    )
}

export default Exercises
