import {useMemo} from 'react'
import {Col, FormGroup, Label, Row} from 'reactstrap'
import {Button, SelectInput} from 'components/general/Control'
import AppInput from 'components/general/Control/Input'

const OptionsTypes = [
    {value: 'string', label: 'String'},
    {value: 'number', label: 'Number'},
    {value: 'boolean', label: 'Boolean'},
]

const OptionsBool = [
    {value: 1, label: 'True'},
    {value: 0, label: 'False'},
]

const DataConfig = ({
    data,
    deleteProperty,
    updatePropertyValue,
    updatePropertyName,
    updatePropertyType,
}) => {
    const formatedData = useMemo(() => {
        return Object.keys(data).map((v) => ({
            propertyName: v,
            propertyValue: data[v],
            propertyType: typeof data[v],
        }))
    }, [data])

    return (
        <>
            {formatedData &&
                formatedData.map((fd, idx) => (
                    <Row key={`property_${idx}`}>
                        <Col className="pl-0 pr-0 pr-sm-0 pr-md-3" sm="3">
                            <FormGroup>
                                <Label>PROPERTY</Label>
                                <AppInput
                                    name={`property_name_${idx}`}
                                    value={fd.propertyName}
                                    setter={(newName) =>
                                        updatePropertyName(
                                            fd.propertyName,
                                            newName
                                        )
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-0 pr-0 pr-sm-0 pr-md-3" sm="3">
                            <FormGroup>
                                <Label>VALUE</Label>
                                {fd.propertyType === 'string' && (
                                    <AppInput
                                        name={`property_value_${idx}`}
                                        value={fd.propertyValue}
                                        setter={(v) =>
                                            updatePropertyValue(
                                                fd.propertyName,
                                                v,
                                                fd.propertyType
                                            )
                                        }
                                    />
                                )}
                                {fd.propertyType === 'boolean' && (
                                    <SelectInput
                                        className={'px-0 w-100'}
                                        name={`property_value_${idx}`}
                                        options={OptionsBool}
                                        selected={Number(fd.propertyValue)}
                                        onChange={(v) =>
                                            updatePropertyValue(
                                                fd.propertyName,
                                                v,
                                                fd.propertyType
                                            )
                                        }
                                        placeholder={'Select Type'}
                                    />
                                )}
                                {fd.propertyType === 'number' && (
                                    <AppInput
                                        type="number"
                                        name={`property_value_${idx}`}
                                        value={fd.propertyValue}
                                        setter={(v) =>
                                            updatePropertyValue(
                                                fd.propertyName,
                                                v,
                                                fd.propertyType
                                            )
                                        }
                                    />
                                )}
                            </FormGroup>
                        </Col>
                        <Col className="pl-0 pr-0 pr-sm-0 pr-md-3" sm="3">
                            <FormGroup>
                                <SelectInput
                                    className={'px-0 w-100'}
                                    name={`property_type_${idx}`}
                                    label={'Type'}
                                    options={OptionsTypes}
                                    selected={fd.propertyType}
                                    onChange={(v) =>
                                        updatePropertyType(fd.propertyName, v)
                                    }
                                    placeholder={'Select Type'}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="pl-0 pr-0 pr-sm-0 pr-md-3" sm="3">
                            <Label>ACTION</Label>
                            <br />
                            <Button
                                size="sm"
                                type="button"
                                onClick={() => deleteProperty(fd.propertyName)}>
                                Delete
                            </Button>
                        </Col>
                    </Row>
                ))}
        </>
    )
}

export default DataConfig
