import {React} from 'common'
import {Page} from 'components/general/Page'
import {
    SelectColumnFilterV2,
    TableWrapperV2,
} from 'components/general/Display/TableV2'
import api from 'services/api'

import {useGetProgramPaginationQuery} from 'store/store'

import {useMemo, useOnSaveCSV, useState, useUrlParams} from 'services/hooks'
import {skipToken} from '@reduxjs/toolkit/dist/query'
import {useCallback, useEffect} from 'react'
import _ from 'lodash'

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Type',
        accessor: 'program_type',
        Filter: SelectColumnFilterV2,
        filterOptions: ['All', 'b2c', 'corporate'],
    },
    {
        Header: 'Author',
        accessor: 'author',
        Filter: SelectColumnFilterV2,
        filterOptions: ['All', 'Hyfit'],
    },
    {
        Header: 'Active',
        accessor: 'activeIcon',
        Filter: SelectColumnFilterV2,
        filterOptions: ['All', 'true', 'false'],
    },
    {
        Header: 'New Version',
        accessor: 'newVersion',
        Filter: SelectColumnFilterV2,
        filterOptions: ['All', 'true', 'false'],
    },
]

const Programs = () => {
    const [isLoading, onSaveCSV] = useOnSaveCSV()

    const [urlParams] = useUrlParams()
    const {
        page_number: page = 0,
        page_size: limit = 10,
        name: keywords = '',
        program_type: programType = 'All',
        author,
        activeIcon: active = 'All',
        newVersion = 'All',
        language = 'en',
        sortBy = 'name',
        sortType = 'asc',
    } = urlParams

    const [shouldFetch, setShouldFetch] = useState(true)
    const programs = useGetProgramPaginationQuery(
        shouldFetch
            ? {
                  page,
                  limit,
                  keywords,
                  programType,
                  author,
                  active,
                  newVersion,
                  language,
                  sortBy,
                  sortType,
              }
            : skipToken
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedRefetch = useCallback(
        _.debounce(() => {
            setShouldFetch(true)
            programs.refetch()
        }, 500),
        []
    )

    useEffect(() => {
        if (keywords) {
            setShouldFetch(false)
            debouncedRefetch()
        }
        return () => {
            debouncedRefetch.cancel()
        }
    }, [debouncedRefetch, keywords])

    const data = useMemo(
        () =>
            programs?.data?.data?.map((prop, key) => {
                return {
                    id: key,
                    name: prop.name,

                    _id: prop.id,
                    pathname: `/admin/program/${prop.id}`,

                    author: prop.author,
                    level: String(prop.level),
                    muscles: prop.muscles,
                    active: prop.active,
                    version: prop.version,
                    activeIcon: prop.active ? 'true' : 'false',
                    newVersion: prop.version > 1 ? 'true' : 'false',
                    program_type: prop.program_type,
                }
            }),
        [programs.data]
    )

    return (
        <Page hideSidbar={false}>
            <TableWrapperV2
                title="Programs"
                isFetching={isLoading}
                onSaveCSV={() => onSaveCSV(api.getProgramsCSV(), 'programs')}
                createNew={'/admin/program/new'}
                data={data}
                metadata={programs?.data?.metadata}
                columns={columns}
            />
        </Page>
    )
}

export default Programs
