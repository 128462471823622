import ConfigDetail from 'components/Config/ConfigDetail'
import MessageAlert from 'components/general/Display/MessageAlert'
import {DetailsWrapper, Page, PageContent} from 'components/general/Page'
import {useState} from 'react'

const Config = () => {
    const [showAlert, setShowAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const onError = (error) => {
        setShowAlert(true)
        setErrorMessage(error)
    }

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    header="Config Detail"
                    backButtonText="Back to Configs">
                    <ConfigDetail onError={onError} />
                </DetailsWrapper>
            </PageContent>
            <MessageAlert
                showAlert={showAlert}
                title={'Failed To Save'}
                mainText={errorMessage}
                confirmBtnText="OK"
                onConfirm={() => setShowAlert(false)}
            />
        </Page>
    )
}

export default Config
