import {levelOptions} from 'constant/workout'
import SelectInput from 'components/general/Control/SelectInput'

const LevelOptions = ({level, setLevel, showError}) => {
    return (
        <SelectInput
            required
            showError={showError}
            selected={level}
            onChange={setLevel}
            options={levelOptions}
            name="level"
            label="Workout Level"
        />
    )
}

export default LevelOptions
