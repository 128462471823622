import React, {useEffect, useState, useCallback, useRef} from 'react'

import {formatDataToDragAndDropFullObject} from 'services/utils'
import DragAndDrop from 'components/general/Control/DragAndDrop'
import styled from 'styled-components'
import {Animations} from 'appearance'
import {useGetPaginationExerciseQuery} from 'store/store'
import _ from 'lodash'

const ExercisesDragAndDrop = ({
    showSelectedExerciseError,
    onUpdateWorkoutTarget,
    setSelectedExercises,
    selectedExercises,
    updatedWorkout,
    setDefaultWork,
    setDefaultRest,
    setDefaultSets,
    defaultWork,
    defaultRest,
    defaultSets,
    isClass,
}) => {
    const [searchText, setSearchText] = useState('')
    const [availableExercises, setAvailableExercises] = useState([])
    const [selectedFilterTypes, setSelectedFilterTypes] = useState({})
    const [checkboxList, setCheckboxList] = useState(selectedExercises)
    const exerciseListRef = useRef()

    const [queryPaginationExercise, setQueryPaginationExercise] = useState({
        active: true,
        newVersion: true,
        language: 'en',
        trainer_gender: '',
        keywords: '',
        actions: [],
        adjuster_positions: [],
        anchor_locations: [],
        anchor_orientations: [],
        band_angles: [],
        contraction_focuses: [],
        exercise_types: [],
        grip_types: [],
        movement_patterns: [],
        muscle_groups: [],
        postures: [],
        primary_planes: [],
        purposes: [],
        segments: [],
    })
    const {data: paginateExercise} = useGetPaginationExerciseQuery(
        queryPaginationExercise
    )

    useEffect(() => {
        if (paginateExercise?.data) {
            setAvailableExercises(
                formatDataToDragAndDropFullObject(paginateExercise?.data)
            )
        }
    }, [paginateExercise])

    useEffect(() => {
        if (showSelectedExerciseError && selectedExercises?.length < 1) {
            exerciseListRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'start',
            })
        }
    }, [selectedExercises, showSelectedExerciseError])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedRefetch = useCallback(
        _.debounce(() => {
            setQueryPaginationExercise((old) => ({
                ...old,
                keywords: searchText,
            }))
        }, 500),
        [searchText]
    )

    useEffect(() => {
        debouncedRefetch()
        return () => {
            debouncedRefetch.cancel()
        }
    }, [debouncedRefetch])

    const handleSelectedFilterTypes = (objFilterTypes) => {
        const keysObj = Object.keys(objFilterTypes)
        setSelectedFilterTypes(objFilterTypes)
        if (keysObj.length) {
            const result = Object.keys(objFilterTypes).reduce((obj, key) => {
                return {...obj, [`${key}s`]: objFilterTypes[key]}
            }, {})

            setQueryPaginationExercise((old) => ({...old, ...result}))
        } else {
            setQueryPaginationExercise((old) => ({
                ...old,
                actions: [],
                adjuster_positions: [],
                anchor_locations: [],
                anchor_orientations: [],
                band_angles: [],
                contraction_focuses: [],
                exercise_types: [],
                grip_types: [],
                movement_patterns: [],
                muscle_groups: [],
                postures: [],
                primary_planes: [],
                purposes: [],
                segments: [],
            }))
        }
    }

    const onRemoveItem = (itemUuid) => {
        const newSelectedItems = selectedExercises.filter(
            (item) => item.uuid !== itemUuid
        )
        const newCheckboxSelectedExercises = checkboxList.filter(
            (item) => !item.activity_type && item.uuid !== itemUuid
        )
        setSelectedExercises(newSelectedItems)
        setCheckboxList(newCheckboxSelectedExercises)
    }

    return (
        <Container
            ref={exerciseListRef}
            error={showSelectedExerciseError}
            className="justify-content-center">
            <DragAndDrop
                showFilter
                isClass={isClass}
                showColumnSelection
                showSelectedExerciseError={showSelectedExerciseError}
                onRemoveItem={onRemoveItem}
                checkboxList={checkboxList}
                setCheckboxList={setCheckboxList}
                searchText={searchText}
                setSearchText={setSearchText}
                filterByGender={queryPaginationExercise?.trainer_gender}
                setFilterByGender={(v) => {
                    setQueryPaginationExercise((old) => ({
                        ...old,
                        trainer_gender: v,
                    }))
                }}
                selectedItems={selectedExercises}
                setSelectedItems={setSelectedExercises}
                collection={availableExercises}
                setCollection={setAvailableExercises}
                updatedWorkout={updatedWorkout}
                onUpdateWorkoutTarget={onUpdateWorkoutTarget}
                dropTitle={'Selected Exercises'}
                dragTitle={'Available Exercises'}
                selectedFilterTypes={selectedFilterTypes}
                setSelectedFilterTypes={handleSelectedFilterTypes}
                defaultWork={defaultWork}
                setDefaultWork={setDefaultWork}
                defaultRest={defaultRest}
                setDefaultRest={setDefaultRest}
                defaultSets={defaultSets}
                setDefaultSets={setDefaultSets}
            />
        </Container>
    )
}

export default ExercisesDragAndDrop

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${({error}) => error && Animations.flickerAnimation};
`
