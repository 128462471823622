import {Col, FormGroup, Label, Row} from 'reactstrap'
import AppInput from '../general/Control/Input.js'
import {Button} from '../general/Control/index.js'

const GearSetupDeviceInfoTag = ({deviceInfoTag, setter, deleteAction}) => {
    return (
        <Row>
            <Col sm="12" md="3">
                <FormGroup>
                    <Label>TITLE</Label>
                    <AppInput
                        value={deviceInfoTag.title}
                        setter={(v) => setter('title', v)}
                    />
                </FormGroup>
            </Col>
            <Col sm="12" md="3">
                <FormGroup>
                    <Label>ICON</Label>
                    <AppInput
                        value={deviceInfoTag.icon}
                        setter={(v) => setter('icon', v)}
                    />
                </FormGroup>
            </Col>
            <Col sm="12" md="3">
                <FormGroup>
                    <Label>ARGUMENT</Label>
                    <AppInput
                        value={deviceInfoTag.args}
                        setter={(v) => setter('args', v)}
                    />
                </FormGroup>
            </Col>
            <Col sm="3">
                <Label>ACTION</Label>
                <br />
                <Button size="sm" type="button" onClick={deleteAction}>
                    Delete Tag
                </Button>
            </Col>
        </Row>
    )
}
export default GearSetupDeviceInfoTag
