import {Col, FormGroup, Label, Row} from 'reactstrap'
import {skipToken} from '@reduxjs/toolkit/dist/query'
import {Button} from 'components/general/Control'
import AppInput from 'components/general/Control/Input'
import {useGetConfigByIdQuery, useSaveConfigMutation} from 'store/slices/config'
import DataConfig from './DataConfig'
import {getSuccessSaveMessageData, showAlertMessage} from 'services/utils'
import {
    useHistory,
    useDispatch,
    useEffect,
    useState,
    useCallback,
    useParams,
} from 'services/hooks'

const ConfigDetail = ({onError}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const isNewConfig = params?.id === 'new'

    const {data: configData} = useGetConfigByIdQuery(
        isNewConfig ? skipToken : params?.id
    )
    const [saveConfig, {isSuccess}] = useSaveConfigMutation()

    const [updateConfig, setUpdateConfig] = useState({})

    const addNewProperty = () => {
        setUpdateConfig((old) => {
            if (!old.data) {
                old.data = {}
            }
            const idx = Object.keys(old.data).length + 1
            const oldData = {...old?.data}
            const newData = Object.assign(oldData, {
                [`property_${idx}`]: 'insert here',
            })
            return {...old, data: newData}
        })
    }

    const deleteProperty = (propertyName) => {
        setUpdateConfig((old) => {
            const {[propertyName]: toRemove, ...newData} = {...old?.data}
            return {...old, data: newData}
        })
    }

    const updatePropertyValue = (propertyName, propertyValue, propertyType) => {
        let newValue
        if (propertyType === 'string') {
            newValue = propertyValue
        } else if (propertyType === 'number') {
            newValue = Number(propertyValue)
        } else if (propertyType === 'boolean') {
            newValue = Boolean(Number(propertyValue))
        }

        setUpdateConfig((old) => {
            const oldData = {...old?.data}
            const newData = {...oldData, [propertyName]: newValue}
            return {...old, data: newData}
        })
    }

    const updatePropertyName = (oldName, newName) => {
        setUpdateConfig((old) => {
            const newData = Object.keys(old?.data).reduce((acc, key) => {
                if (key === oldName) {
                    acc[newName] = old?.data[oldName]
                } else {
                    acc[key] = old?.data[key]
                }
                return acc
            }, {})

            return {...old, data: newData}
        })
    }

    const updatePropertyType = (propertyName, propertyType) => {
        let newValue
        if (propertyType === 'string') {
            newValue = 'insert here'
        } else if (propertyType === 'number') {
            newValue = Number(0)
        } else if (propertyType === 'boolean') {
            newValue = Boolean(0)
        }

        setUpdateConfig((old) => {
            const oldData = {...old?.data}
            const newData = {...oldData, [propertyName]: newValue}
            return {...old, data: newData}
        })
    }

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    const onSave = (ev) => {
        ev?.preventDefault()

        saveConfig({config: updateConfig, isNewConfig})
            .unwrap()
            .catch((r) => {
                onError(r?.data?.message)
            })
    }

    useEffect(() => {
        if (configData) {
            setUpdateConfig({
                _id: configData._id,
                name: configData.name,
                data: configData.data || {},
            })
        }
    }, [configData])

    useEffect(() => {
        if (isSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(
                updateConfig?.name
            )

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isSuccess, redirectToList, updateConfig])

    return (
        <div className="px-3">
            <Row>
                <Col className="pl-0 pr-0 pr-sm-0 pr-md-3" sm="6">
                    <FormGroup>
                        <Label>CONFIG NAME</Label>
                        <AppInput
                            name="name"
                            value={updateConfig?.name}
                            setter={(v) => {
                                setUpdateConfig((old) => ({...old, name: v}))
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>

            {/* DATA CONFIG */}
            <Row>
                <Col className="pl-0">
                    <Label>DATA CONFIG</Label>
                </Col>
                <Col className="pl-0" sm="12">
                    <Button size="sm" type="button" onClick={addNewProperty}>
                        Add Property
                    </Button>
                </Col>
            </Row>
            <DataConfig
                data={updateConfig?.data || {}}
                deleteProperty={deleteProperty}
                updatePropertyValue={updatePropertyValue}
                updatePropertyName={updatePropertyName}
                updatePropertyType={updatePropertyType}
            />

            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="save_button"
                    color="primary"
                    type="submit"
                    onClick={onSave}>
                    {isNewConfig ? 'CREATE' : 'MODIFY'} CONFIG
                </Button>
            </Row>
        </div>
    )
}

export default ConfigDetail
