import {React, styled} from 'common'

import {Row} from 'reactstrap'
import {CloseButton} from 'react-bootstrap'
import {exerciseFilters} from 'constant/exercise'

const getFilterName = (filterType) =>
    (filterType.display_name || filterType.label).replaceAll('_', ' ')

const FilterBubbles = ({selectedFilterTypes, setSelectedFilterTypes}) => {
    if (!Object.keys(selectedFilterTypes).length) {
        return null
    }
    const removeFilterLabel = (label) => {
        const newObj = {}
        Object.keys(selectedFilterTypes)?.map(
            (key) => key !== label && (newObj[key] = selectedFilterTypes[key])
        )
        setSelectedFilterTypes(newObj)
    }
    const findLabel = (label) =>
        exerciseFilters.find((filter) => filter.label === label)

    return (
        <Row className="mx-0 mb-1">
            {Object.keys(selectedFilterTypes)?.map((filter) => {
                const filterType = findLabel(filter)
                return (
                    <BubbleContainer
                        key={'filter_bubble_' + filter}
                        onClick={() => removeFilterLabel(filter)}
                        className=" btn d-flex justify-content-between px-1 py-1 btn-simple">
                        <DeleteButton />
                        <BubbleLabel className="mb-0 mx-2 d-flex flex-fill">
                            {getFilterName(filterType)}
                        </BubbleLabel>
                    </BubbleContainer>
                )
            })}
        </Row>
    )
}

export default FilterBubbles

const DeleteButton = styled(CloseButton)`
    z-index: 3;
    span {
        color: #666666 !important;
        border-radius: 3px;
    }
    &:hover > span {
        color: #939393 !important;
    }
`

const BubbleContainer = styled.div``

const BubbleLabel = styled.p`
    text-transform: capitalize;
`
