import {Col} from 'components/general/Control'
import React from 'react'
import SingleCheckbox from 'components/general/Control/SingleCheckbox'

const DemoWorkout = ({isDemo = false, setIsDemo}) => {
    return (
        <Col className="mb-2 mr-md-3">
            <SingleCheckbox
                fontSize={15}
                option={{
                    label: 'SET AS DEMO WORKOUT',
                    value: isDemo,
                }}
                onChange={setIsDemo}
            />
        </Col>
    )
}
export default DemoWorkout
