import {useMemo} from 'react'
import {TableWrapperV2} from 'components/general/Display/TableV2'
import {Page} from 'components/general/Page'
import {useGetPaginationConfigQuery} from 'store/slices/config'

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
    },
]

const Configs = () => {
    const configs = useGetPaginationConfigQuery()

    const dataConfig = useMemo(() => {
        return configs?.data?.data?.map((prop, key) => ({
            id: key,
            name: prop.name,
            _id: prop._id,
            pathname: `/admin/config/${prop._id}`,
        }))
    }, [configs?.data?.data])

    return (
        <Page>
            <TableWrapperV2
                title="Configs"
                isFetching={false}
                createNew={'/admin/config/new'}
                data={dataConfig}
                metadata={configs?.data?.metadata}
                columns={columns}
            />
        </Page>
    )
}

export default Configs
