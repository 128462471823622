import AppInput from 'components/general/Control/Input'
import React from 'react'
import {Col, Label} from 'reactstrap'

const CreatedByAuthor = ({author, setAuthor}) => {
    return (
        <Col md={6} className="px-0 pr-md-3">
            <Label>Created By (Author)</Label>
            <AppInput
                value={author}
                setter={setAuthor}
                name="author"
                placeholder="Enter Author Name"
            />
        </Col>
    )
}

export default CreatedByAuthor
