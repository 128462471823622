export const workoutMandatoryFields = {
    general: [
        'name',
        'difficulty_level',
        'background_music',
        'goal',
        'image',
        'tall_image',
        'small_image',
        'overall_goals',
        'body_region_focus',
        'estimated_work',
        'estimated_calories',
    ],
}

export const trainingGoals = [
    {label: 'Lose Weight', value: 'lose_weight'},
    {label: 'Maintain Health', value: 'maintain_health'},
    {label: 'Build Strength', value: 'build_strength'},
    {label: 'Tone Up', value: 'tone_up'},
    {label: 'Recreate', value: 'recreate'},
    {label: 'Relieve Stress', value: 'relieve_stress'},
]

export const bodyRegionsFocus = [
    {label: 'Core', value: 'core'},
    {label: 'Total Body', value: 'balanced'},
    {label: 'Upper Body', value: 'upper_body'},
    {label: 'Lower Body', value: 'lower_body'},
]

export const categoriesOptions = [
    {label: 'Core', value: 'core'},
    {label: 'Strength', value: 'strength'},
    {label: 'Cardio', value: 'cardio'},
    {label: 'Mobility', value: 'mobility'},
    {label: 'Stretch', value: 'stretch'},
]

export const levelOptions = [
    {label: 'Beginner', value: 1},
    {label: 'Intermediate', value: 2},
    {label: 'Expert', value: 3},
]

export const objectiveOptions = [
    {label: 'Calorie Burn', value: 'calorie_burn'},
    {label: 'Overall Strength', value: 'overall_strength'},
    {label: 'Focused Strength', value: 'focused_strength'},
    {label: 'Mobility', value: 'mobility'},
    {label: 'Stability', value: 'stability'},
]

export const backgroundMusicOptions = [
    {
        value: 'mixdown_trap_1',
        label: 'Mixdown Trap 1',
    },
    {
        value: 'rock_1',
        label: 'Rock 1',
    },
    {
        value: 'rock_2',
        label: 'Rock 2',
    },
    {
        value: 'rock_3',
        label: 'Rock 3',
    },
    {
        value: 'vocal_house_1',
        label: 'Vocal House 1',
    },
    {
        value: 'vocal_house_2',
        label: 'Vocal House 2',
    },
    {
        value: 'vocal_trance_1',
        label: 'Vocal Trance 1',
    },
    {
        value: 'vocal_trance_2',
        label: 'Vocal Trance 2',
    },
]

export const workoutAccessOptions = [
    {
        value: 'paid',
        label: 'Paid',
    },
    {
        value: 'free',
        label: 'Free',
    },
]
export const workoutTypeOptions = [
    {
        value: 'modular',
        label: 'Modular',
    },
    {
        value: 'class',
        label: 'Class',
    },
    {
        value: 'short',
        label: 'Short',
    },
]

export const WorkoutContentType = {
    Strength: 'strength',
    Aerobic: 'aerobic',
}

export const WorkoutContentTypeOptions = Object.keys(WorkoutContentType).map(
    (key) => ({value: WorkoutContentType[key], label: key})
)

export const WatchActivityType = {
    GearGo: 'GEAR GO',
    HyRope: 'HYROPE',
    Walk: 'Walk',
    Run: 'Run',
    Cycling: 'Cycling',
    BallGames: 'Ball Games',
    Other: 'Other',
}

export const WatchActivityTypeOptions = Object.values(WatchActivityType).map(
    (v) => ({value: v, label: v})
)

export const TYPE_EXERCISE = 'exercise'

export const TARGET_EXERCISE_TIME = 'exercise_time'
export const TARGET_TOTAL_WORK = 'total_work'
export const TARGET_REPETITIONS = 'repetitions'
export const TARGET_CALORIES = 'calories'
export const TARGET_TOTAL_WEIGHT = 'total_weight'

export const targets = {
    TARGET_EXERCISE_TIME,
    TARGET_TOTAL_WORK,
    TARGET_REPETITIONS,
    TARGET_CALORIES,
    TARGET_TOTAL_WEIGHT,
}
export const WORKOUT_TARGETS = [
    {
        value: TARGET_EXERCISE_TIME,
        label: 'Time',
    },
    {
        value: TARGET_REPETITIONS,
        label: 'Reps',
    },
]
