import {createApi} from '@reduxjs/toolkit/query/react'
import {axiosBaseQuery} from 'store/common'

const transformResponseFromData = (response) => response.data

const transformResponseFromMessage = (response) => response.message

const provideTags = (tagName, data) =>
    data
        ? [
              ...data.map(({id}) => ({type: tagName, id})),
              {type: tagName, id: 'LIST'},
          ]
        : [{type: tagName, id: 'LIST'}]

const provideTag = (tagName, data, tagField) =>
    data ? {type: tagName, id: data[tagField]} : {}

const workoutApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'workout',
    tagTypes: ['Workout', 'Csv', 'AssosiatedPrograms', 'WorkoutPagination'],
    endpoints: (build) => ({
        getPaginationWorkout: build.query({
            query: (params) => ({
                url: 'admin/program/workout/pagination',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) =>
                provideTags('WorkoutPagination', data?.data),
        }),
        getWorkouts: build.query({
            query: (params) => ({
                url: 'admin/program/workout',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Workout', data?.data),
        }),
        getWorkout: build.query({
            query: ({id, language}) => {
                return {
                    url: `/admin/program/workout/${id}/${language}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Workout', data, 'id')],
        }),

        saveWorkout: build.mutation({
            query: ({workout, id}) => ({
                url: id
                    ? `/admin/program/workout/${id}`
                    : '/admin/program/workout',
                method: id ? 'PUT' : 'POST',
                body: workout,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Workout', data, 'id')],
            invalidatesTags: (result, error, data) => [
                {type: 'Workout', id: data?.id},
                {type: 'Workout', id: 'LIST'},
                {type: 'WorkoutPagination', id: 'LIST'},
            ],
        }),
        saveWorkoutTranslation: build.mutation({
            query: (body) => ({
                url: '/admin/translation/',
                method: 'POST',
                body,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Workout', data, 'id')],
            invalidatesTags: (result, error, data) => [
                {type: 'Workout', id: data?.id},
                {type: 'WorkoutPagination', id: 'LIST'},
            ],
        }),
        getWorkoutsSpreadsheet: build.query({
            query: () => 'admin/program/workout/csv',
            transformResponse: transformResponseFromMessage,
            providesTags: ({data}) => provideTags('Csv', data),
        }),
        deleteWorkout: build.mutation({
            query: (id) => {
                return {
                    url: `/admin/program/workout/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result, error, data) => [
                {type: 'Workout', id: data?.id},
                {type: 'WorkoutPagination', id: 'LIST'},
            ],
        }),
    }),
})
export default workoutApi

export const {
    useGetWorkoutsQuery,
    useGetWorkoutQuery,
    useSaveWorkoutMutation,
    useSaveWorkoutTranslationMutation,
    useGetWorkoutsSpreadsheetQuery,
    useDeleteWorkoutMutation,
    useGetPaginationWorkoutQuery,
} = workoutApi
