import {React} from 'common'
import TableHeaderTitleAndSort from './TableHeaderTitleAndSort'
import TablePaginationV2 from './TablePagination'

const TableHeaderV2 = ({
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageIndex,
    pageSize,
    setPageSize,
    gotoPage,
    pageOptions,
    pageCount,
    headerGroups,
    disablePagination = false,
}) => {
    return (
        <thead>
            {!disablePagination && (
                <TablePaginationV2
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    gotoPage={gotoPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                />
            )}
            <TableHeaderTitleAndSort headerGroups={headerGroups} />
        </thead>
    )
}

export default TableHeaderV2
