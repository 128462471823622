import React from 'react'
import {
    BackgroundMusicSelection,
    BodyRegionsFocus,
    WorkoutImages,
    WorkoutObjective,
} from 'components/Workout/Details'

import EstimatedWorkAndCalories from 'components/Workout/Details/EstimatedWorkAndCalories'
import styled from 'styled-components'
import OverallTrainingGoal from 'components/Workout/Details/OverallTrainingGoal'
import {Col, Row} from 'reactstrap'
import {Language} from 'components/general/Display'
import Instructions from 'components/Details/Instructions'
import WorkoutDifficulty from 'components/Workout/Details/WorkoutDifficulty'
import WorkoutAccessSelection from 'components/Workout/Details/WorkoutAccessSelection'
import WorkoutTypeSelection from 'components/Workout/Details/WorkoutTypeSelection'
import DemoWorkout from './DemoWorkout'
import WorkoutContentTypeSelection from './WorkoutContentTypeSelection'
import WorkoutWatchActivityTypeSelection from './WorkoutWatchActivityTypeSelection'
import LevelOptions from './Level'
// import CategoryWorkout from './CategoryWorkout'
import CreatedByAuthor from './CreatedByAuthor'
import NameAndDisplayName from '../../Details/NameAndDisplayName.js'

// eslint-disable-next-line complexity
const WorkoutGeneralContent = ({
    setImageFile,
    setLongImage,
    setSmallImage,
    imageFile,
    longImage,
    smallImage,
    language,
    setLanguage,
    showError,
    showDraftError,
    showNameError,
    updatedWorkout,
    isNewWorkout,
    updateWorkoutField,
    seletedExercises,
    onShowClassError,
}) => {
    const {
        name,
        display_name,
        overall_goals,
        goal,
        is_demo,
        instructions,
        difficulty_level,
        body_region_focus,
        background_music,
        estimated_work,
        estimated_calories,
        workout_access,
        workout_type,
        content_type,
        watch_activity_type,
        level,
        // category,
        author,
    } = updatedWorkout || {}

    return (
        <Container>
            <Row className="px-3">
                <Language
                    hide={isNewWorkout}
                    className={'px-0'}
                    language={language}
                    setLanguage={setLanguage}
                />
            </Row>
            <NameAndDisplayName
                className="px-3 flex-grow-1"
                label={'WORKOUT'}
                name={name}
                setName={(value) => updateWorkoutField('name', value)}
                displayName={display_name || ''}
                setDisplayName={(value) =>
                    updateWorkoutField('display_name', value)
                }
                nameError={showDraftError || showError}
                showError={showDraftError || showError}
            />
            <Row className="justify-content-between px-3">
                <Instructions
                    className="col-md-6 col-lg-12 mb-sm-0"
                    instructions={instructions}
                    setInstractions={(value) =>
                        updateWorkoutField('instructions', [value])
                    }
                />
                <DemoWorkout
                    isDemo={is_demo}
                    setIsDemo={(value) => updateWorkoutField('is_demo', value)}
                />
            </Row>
            <Row form>
                <WorkoutAccessSelection
                    workoutAccess={workout_access}
                    setWorkoutAccess={(value) =>
                        updateWorkoutField('workout_access', value)
                    }
                    showError={showError}
                />
                <WorkoutTypeSelection
                    onShowClassError={onShowClassError}
                    workoutType={workout_type}
                    seletedExercises={seletedExercises}
                    setWorkoutType={(value) =>
                        updateWorkoutField('workout_type', value)
                    }
                    showError={showError}
                />
                <WorkoutContentTypeSelection
                    showError={showError}
                    contentType={content_type}
                    setContentType={(v) => {
                        updateWorkoutField('content_type', v)
                    }}
                />
                {content_type === 'aerobic' && (
                    <WorkoutWatchActivityTypeSelection
                        showError={showError}
                        watchActivityType={watch_activity_type}
                        setWatchActivityType={(v) => {
                            updateWorkoutField('watch_activity_type', v)
                        }}
                    />
                )}
            </Row>
            <Row className="justify-content-between px-3">
                <OverallTrainingGoal
                    required
                    showError={showError}
                    selected={overall_goals || []}
                    updateField={updateWorkoutField}
                />
                <Col className="px-0 " md={6}>
                    <WorkoutObjective
                        showError={showError}
                        objective={goal}
                        setObjective={(value) =>
                            updateWorkoutField('goal', value)
                        }
                    />
                    <BodyRegionsFocus
                        showError={showError}
                        region={body_region_focus}
                        setRegion={(value) =>
                            updateWorkoutField('body_region_focus', value)
                        }
                    />
                </Col>
            </Row>

            {/* <CategoryWorkout
                category={category}
                setCategory={(v) => updateWorkoutField('category', v)}
                showError={showError}
            /> */}

            <Row className="px-3">
                <WorkoutDifficulty
                    difficulty={difficulty_level}
                    setDifficulty={(value) =>
                        updateWorkoutField('difficulty_level', value)
                    }
                    showError={showError}
                />

                <LevelOptions
                    level={level}
                    setLevel={(v) => updateWorkoutField('level', v)}
                    showError={showError}
                />
            </Row>

            <CreatedByAuthor
                author={author}
                setAuthor={(v) => updateWorkoutField('author', v)}
            />

            <WorkoutImages
                imageFile={imageFile}
                setImageFile={setImageFile}
                longImage={longImage}
                setLongImage={setLongImage}
                smallImage={smallImage}
                setSmallImage={setSmallImage}
                showError={showError}
            />
            <BackgroundMusicSelection
                showError={showError}
                backgroundMusic={background_music}
                setBackgroundMusic={(value) =>
                    updateWorkoutField('background_music', value)
                }
            />
            <EstimatedWorkAndCalories
                showError={showError}
                required
                estimatedWork={estimated_work}
                setEstimatedWork={(value) =>
                    updateWorkoutField('estimated_work', value)
                }
                estimatedCalories={estimated_calories}
                setEstimatedCalories={(value) =>
                    updateWorkoutField('estimated_calories', value)
                }
            />
        </Container>
    )
}

export default WorkoutGeneralContent

const Container = styled.div``
