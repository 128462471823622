const exerciseEquipment = [
    {
        label: 'Gear 1',
        value: 'normal',
        // value: 'gear_1',
    },
    {
        label: 'GearX',
        value: 'gearX',
    },
    // {
    //     label: 'Transition',
    //     value: 'transition',
    // },

    // {
    //     label: 'Warmup / Stretching',
    //     value: 'warmup_stretcing',
    // },
    {
        label: 'Rope',
        value: 'rope',
    },
    {
        label: 'Bar',
        value: 'bar',
    },
    {
        label: 'Gear Go',
        value: 'gearGo',
    },
    {
        label: 'Body Weight',
        value: 'no_bands',
        // value: 'body_weight',
    },
    {
        label: 'Motion Analysis',
        value: 'rtf',
    },
    {
        label: 'Spider B',
        value: 'spiderB',
    },
    {
        label: 'Spider S',
        value: 'spiderS',
    },
]

const gripTypes = [
    {
        label: 'Handles',
        value: 'handles',
    },
    {
        label: 'Cuff',
        value: 'cuff',
    },
    {
        label: 'Bar',
        value: 'bar',
    },
    {
        label: 'Loop',
        value: 'loop',
    },

    // {
    //     label: 'Ankle Band',
    //     value: 'ankle_band',
    // },
    // {
    //     label: 'Wrist Band',
    //     value: 'wrist_band',
    // },
]

const barModes = [
    {
        label: 'Strength',
        value: 'strength',
    },
    {
        label: 'Performance',
        value: 'performance',
    },
]

const barGripModes = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: 'DUAL',
        value: '5',
    },
]

const anchorPositionTypes = [
    {
        label: 'Low',
        value: 'low',
    },
    {
        label: 'Middle',
        value: 'mid',
    },
    {
        label: 'High',
        value: 'up',
    },
    {
        label: 'Standing',
        value: 'standing',
    },
    {
        label: 'None',
        value: 'none',
    },
]

const anchorLocationOptions = [
    {
        label: 'Center',
        value: 'center',
    },
    {
        label: 'End',
        value: 'end',
    },
]

const userPositionTypes = [
    {
        label: '45',
        value: '45',
    },
    {
        label: '90',
        value: '90',
    },
    {
        label: 'Frontal',
        value: '0',
    },
]

const adjusterPositionTypes = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
]

const exerciseBodyAreaOptions = [
    {
        label: 'Low Back & Sides',
        value: 'low_back_and_sides',
    },
    {
        label: 'Bodyweight Lower',
        value: 'bodyweight_lower',
    },
    {
        label: 'Isolated Upper',
        value: 'isolated_upper',
    },
    {
        label: 'Compound Upper',
        value: 'compound_upper',
    },
    {
        label: 'Compound Lower',
        value: 'compound_lower',
    },
    {
        label: 'Isolated Lower',
        value: 'isolated_lower',
    },
    {
        label: 'Abdominals',
        value: 'abdominals',
    },
    {
        label: 'Jumps & Lunges',
        value: 'jumps_and_lunges',
    },
]

const caloriesMETOptions = [
    {
        label: 'Sitting/kneeling - 1.5',
        value: 1.5,
    },
    {
        label: 'Standing - 2',
        value: 2,
    },
    {
        label: 'Mild stretching - 2.3',
        value: 2.3,
    },
    {
        label: 'Planks - 3',
        value: 3,
    },
    {
        label: 'Standing mobility - 3.5',
        value: 3.5,
    },
    {
        label: 'Active stretching - 3.5',
        value: 3.5,
    },
    {
        label: 'Chest/Rear Delt Fly - 3.5',
        value: 3.5,
    },
    {
        label: 'Yoga poses - 4',
        value: 4,
    },
    {
        label: 'Pushups - 4.5',
        value: 4.5,
    },
    {
        label: 'Situps - 4.5',
        value: 4.5,
    },
    {
        label: 'Chest Press/ Row - 4.5',
        value: 4.5,
    },
    {
        label: 'Carrying - 5',
        value: 5,
    },
    {
        label: 'Squats - 5.5',
        value: 5.5,
    },
    {
        label: 'Bear crawls - 6',
        value: 6,
    },
    {
        label: 'Crab walks - 6',
        value: 6,
    },
    {
        label: 'Shaddow boxing - 6',
        value: 6,
    },
    {
        label: 'Mountain climbers - 6',
        value: 6,
    },
    {
        label: 'Jogging - 7',
        value: 7,
    },
    {
        label: 'Jumping jacks - 8',
        value: 8,
    },
    {
        label: 'Rope jumping - slow - 8',
        value: 8,
    },
    {
        label: 'Swinging - 8',
        value: 8,
    },
    {
        label: 'Running in place - 8',
        value: 8,
    },
    {
        label: 'Lunging - 8',
        value: 8,
    },
    {
        label: 'Jumping - 9',
        value: 9,
    },
    {
        label: 'Burpees - 10',
        value: 10,
    },
    {
        label: 'Jump Squat - 10',
        value: 10,
    },
    {
        label: 'Running - 6.0 mph - 10',
        value: 10,
    },
    {
        label: 'Rope jumping - fast - 12',
        value: 12,
    },
    {
        label: 'Running - 8.0 mph - 13.5',
        value: 13.5,
    },
]

const MOTION_ANALYSIS_KEYS = [
    {label: 'Squat', value: 1, calssName: 'dropdown-item'},
    {label: 'Push-up', value: 2, calssName: 'dropdown-item'},
    {label: 'Plank', value: 3, calssName: 'dropdown-item'},
    {label: 'Hip Abduction', value: 4, calssName: 'dropdown-item'},
    {label: 'Side Leg Raise', value: 5, calssName: 'dropdown-item'},
    {label: 'Standing Knee Flexion', value: 6, calssName: 'dropdown-item'},
    {label: 'Jumping Jack', value: 7, calssName: 'dropdown-item'},
    {label: 'Superman', value: 8, calssName: 'dropdown-item'},
    {label: 'Pelvic Bridge', value: 9, calssName: 'dropdown-item'},
    {label: 'Pelvic Bridge Dynamic', value: 10, calssName: 'dropdown-item'},
    {label: 'Standing Reach', value: 11, calssName: 'dropdown-item'},
    {label: 'Good Morning', value: 12, calssName: 'dropdown-item'},
    {label: 'Iron Chair', value: 13, calssName: 'dropdown-item'},
    {label: 'Deadlift', value: 14, calssName: 'dropdown-item'},
    {label: 'Sit-up', value: 15, calssName: 'dropdown-item'},
    {label: 'Knee Push-up', value: 16, calssName: 'dropdown-item'},
    {label: 'High Knees', value: 17, calssName: 'dropdown-item'},
    {label: 'Side Plank', value: 18, calssName: 'dropdown-item'},
    {label: 'Swinging High Kicks', value: 19, calssName: 'dropdown-item'},
    {label: 'Handsplank', value: 20, calssName: 'dropdown-item'},
    {label: 'Lunge', value: 21, calssName: 'dropdown-item'},
    {label: 'Donkey Kick', value: 22, calssName: 'dropdown-item'},
    {label: 'Tricep Press', value: 23, calssName: 'dropdown-item'},
    {label: 'Quad Superman', value: 24, calssName: 'dropdown-item'},
    {label: 'Tree Pose', value: 25, calssName: 'dropdown-item'},
    {label: 'One Legged Pelvic Bridge', value: 26, calssName: 'dropdown-item'},
    {label: 'Crunches', value: 27, calssName: 'dropdown-item'},
    {label: '-', value: 28, calssName: 'dropdown-item'},
    {label: 'Bent Knee Raise', value: 29, calssName: 'dropdown-item'},
    {label: 'One Legged Stand', value: 30, calssName: 'dropdown-item'},
    {label: '-', value: 31, calssName: 'dropdown-item'},
    {label: 'Active Elevation Right', value: 32, calssName: 'dropdown-item'},
    {label: 'AROM Elbow Standing Right', value: 33, calssName: 'dropdown-item'},
    {label: 'Push Up (2)', value: 34, calssName: 'dropdown-item'},
    {label: 'Knee Push-up (2)', value: 35, calssName: 'dropdown-item'},
    {label: 'Sit-up (2)', value: 36, calssName: 'dropdown-item'},
    {
        label: 'Standing Hip Flexion Right',
        value: 37,
        calssName: 'dropdown-item',
    },
    {label: 'Leg Extension Right', value: 38, calssName: 'dropdown-item'},
    {label: 'Biceps Curl', value: 39, calssName: 'dropdown-item'},
    {label: 'Goblet Squat', value: 40, calssName: 'dropdown-item'},
    {label: 'Heel Slides Right', value: 41, calssName: 'dropdown-item'},
    {
        label: 'AAROM Elevation Supine Wand',
        value: 42,
        calssName: 'dropdown-item',
    },
    {label: 'Shoulder Press', value: 43, calssName: 'dropdown-item'},
    {label: 'Active Elevation Left', value: 44, calssName: 'dropdown-item'},
    {label: 'AROM Elbow Standing Left', value: 45, calssName: 'dropdown-item'},
    {label: 'Standing Hip Flexion Left', value: 46, calssName: 'dropdown-item'},
    {label: 'Leg Extension Left ', value: 47, calssName: 'dropdown-item'},
    {label: 'Bent Row', value: 48, calssName: 'dropdown-item'},
    {label: '-', value: 49, calssName: 'dropdown-item'},
    {label: 'Rear Delt Fly', value: 50, calssName: 'dropdown-item'},
    {label: 'Cross Country Classic', value: 51, calssName: 'dropdown-item'},
    {label: 'Mini Squat', value: 52, calssName: 'dropdown-item'},
    {
        label: 'Standing Hamstring Curl Right',
        value: 53,
        calssName: 'dropdown-item',
    },
    {label: 'Squat To Chair', value: 54, calssName: 'dropdown-item'},
    {
        label: 'Prone Hamstring Curl Right',
        value: 55,
        calssName: 'dropdown-item',
    },
    {label: 'Straight Leg Raise Right', value: 56, calssName: 'dropdown-item'},
    {label: 'Wall Walks', value: 57, calssName: 'dropdown-item'},
    {label: 'Table Slides', value: 58, calssName: 'dropdown-item'},
    {label: 'Pully Elevation Right', value: 59, calssName: 'dropdown-item'},
    {label: 'Pully Elevation Left', value: 60, calssName: 'dropdown-item'},
    {label: 'Long Arc Quad Right', value: 61, calssName: 'dropdown-item'},
    {label: 'Long Arc Quad Left', value: 62, calssName: 'dropdown-item'},
    {
        label: 'Side Lying Hip Abduction Right',
        value: 63,
        calssName: 'dropdown-item',
    },
    {
        label: 'Side Lying Hip Abduction Left',
        value: 64,
        calssName: 'dropdown-item',
    },
    {label: 'Heel Slides Left', value: 65, calssName: 'dropdown-item'},
    {
        label: 'Standing Hamstring Curl Left',
        value: 66,
        calssName: 'dropdown-item',
    },
    {label: 'Prone Hamstring Curl Left', value: 67, calssName: 'dropdown-item'},
    {label: 'Straight Leg Raise Left', value: 68, calssName: 'dropdown-item'},
    {label: 'Pendulum Right', value: 69, calssName: 'dropdown-item'},
    {label: 'Pendulum Left', value: 70, calssName: 'dropdown-item'},
    {label: 'Seated Knee Flexion Right', value: 71, calssName: 'dropdown-item'},
    {label: 'Seated Knee Flexion Left', value: 72, calssName: 'dropdown-item'},
    {label: 'Short Arc Quad Right', value: 73, calssName: 'dropdown-item'},
    {label: 'Short Arc Quad Left', value: 74, calssName: 'dropdown-item'},
    {label: 'Balance Reach', value: 75, calssName: 'dropdown-item'},
    {label: 'Shoulder Extension Left', value: 76, calssName: 'dropdown-item'},
    {label: 'Chest Press', value: 77, calssName: 'dropdown-item'},
    {label: 'Front Raise', value: 78, calssName: 'dropdown-item'},
    {label: 'Shoulder Extension Right', value: 79, calssName: 'dropdown-item'},
    {label: 'Squat (3)', value: 80, calssName: 'dropdown-item'},
    {label: 'Iron Chair (3)', value: 81, calssName: 'dropdown-item'},
    {label: 'Goblet Squat (3)', value: 82, calssName: 'dropdown-item'},
    {label: 'Plank (3)', value: 83, calssName: 'dropdown-item'},
    {label: 'Triceps Kickback', value: 84, calssName: 'dropdown-item'},
    {label: 'Chest Fly (3)', value: 85, calssName: 'dropdown-item'},
    {label: 'High Mountain Pose', value: 86, calssName: 'dropdown-item'},
    {label: 'Downward Facing Dog', value: 87, calssName: 'dropdown-item'},
    {label: 'Upward Facing Dog', value: 88, calssName: 'dropdown-item'},
    {label: 'Chair Pose', value: 89, calssName: 'dropdown-item'},
    {label: 'Forward Fold', value: 90, calssName: 'dropdown-item'},
    {label: 'Warrior I', value: 91, calssName: 'dropdown-item'},
    {label: 'Warrior II', value: 92, calssName: 'dropdown-item'},
    {label: 'Warrior III', value: 93, calssName: 'dropdown-item'},
    {label: 'Triangle Pose', value: 94, calssName: 'dropdown-item'},
    {label: 'Boat Pose', value: 95, calssName: 'dropdown-item'},
    {label: 'Bow Pose', value: 96, calssName: 'dropdown-item'},
    {label: 'Crow Pose', value: 97, calssName: 'dropdown-item'},
    {label: 'Crescent Low Lunge Pose', value: 98, calssName: 'dropdown-item'},
    {label: 'Extended Side Angle Pose', value: 99, calssName: 'dropdown-item'},
    {
        label: 'Standing Hip Abduction Right',
        value: 100,
        calssName: 'dropdown-item',
    },
    {
        label: 'Table Slides Abduction Right',
        value: 101,
        calssName: 'dropdown-item',
    },
    {
        label: 'Table Slides Abduction Left',
        value: 102,
        calssName: 'dropdown-item',
    },
    {
        label: 'External Rotation Abduction 90 Degs Right',
        value: 103,
        calssName: 'dropdown-item',
    },
    {
        label: 'External Rotation Abduction 90 Degs Left',
        value: 104,
        calssName: 'dropdown-item',
    },
    {
        label: 'Internal Rotation Abduction 90 Degs Left',
        value: 105,
        calssName: 'dropdown-item',
    },
    {
        label: 'Internal Rotation Abduction 90 Degs Right',
        value: 106,
        calssName: 'dropdown-item',
    },
    {
        label: 'External Rotation Arm by Side Right',
        value: 107,
        calssName: 'dropdown-item',
    },
    {
        label: 'Shoulder Side Raise Right',
        value: 108,
        calssName: 'dropdown-item',
    },
    {label: 'Shoulder Side Raise Left', value: 109, calssName: 'dropdown-item'},
    {
        label: 'External Rotation Arm by Side Left',
        value: 110,
        calssName: 'dropdown-item',
    },
    {
        label: 'Internal Rotation Arm by Side Right',
        value: 111,
        calssName: 'dropdown-item',
    },
    {
        label: 'Internal Rotation Arm by Side Left',
        value: 112,
        calssName: 'dropdown-item',
    },
    {
        label: 'Assisted Shoulder Abduction Right',
        value: 113,
        calssName: 'dropdown-item',
    },
    {
        label: 'Assisted Shoulder Abduction Left',
        value: 114,
        calssName: 'dropdown-item',
    },
    {
        label: 'Standing Hip Abduction Left',
        value: 115,
        calssName: 'dropdown-item',
    },
    {
        label: 'Prone Shoulder Extension Right',
        value: 116,
        calssName: 'dropdown-item',
    },
    {label: 'Band Standing Row', value: 117, calssName: 'dropdown-item'},
    {
        label: 'Hip Extension Leaning Over Table Right',
        value: 118,
        calssName: 'dropdown-item',
    },
    {
        label: 'Hip Extension Leaning Over Table Left',
        value: 119,
        calssName: 'dropdown-item',
    },
    {
        label: 'Prone Shoulder Extension Left',
        value: 120,
        calssName: 'dropdown-item',
    },
    {label: 'Prone T', value: 121, calssName: 'dropdown-item'},
    {
        label: 'Supine External Rotation 90 Deg Right',
        value: 122,
        calssName: 'dropdown-item',
    },
    {
        label: 'Side Lying Shoulder External Rotation Right',
        value: 123,
        calssName: 'dropdown-item',
    },
    {
        label: 'Supine Internal Rotation 90 Deg Right',
        value: 124,
        calssName: 'dropdown-item',
    },
    {
        label: 'Side Lying Shoulder External Rotation Left',
        value: 125,
        calssName: 'dropdown-item',
    },
    {
        label: 'Supine External Rotation 90 Deg Left',
        value: 126,
        calssName: 'dropdown-item',
    },
    {
        label: 'Supine Internal Rotation 90 Deg Left',
        value: 127,
        calssName: 'dropdown-item',
    },
    {
        label: 'Superman One Legged Stand',
        value: 128,
        calssName: 'dropdown-item',
    },
    {label: 'Shoulder ROM MVC', value: 129, calssName: 'dropdown-item'},
    {
        label: 'Internal Rotation Cane Behind Back',
        value: 130,
        calssName: 'dropdown-item',
    },
    {
        label: 'Elbow Plank to High Plank',
        value: 131,
        calssName: 'dropdown-item',
    },
    {label: 'V-up', value: 132, calssName: 'dropdown-item'},
    {
        label: 'Internal Rotation Behind Back Abduction Right',
        value: 133,
        calssName: 'dropdown-item',
    },
    {label: '-', value: 134, calssName: 'dropdown-item'},
    {
        label: 'Supine Assisted Shoulder Abduction Right',
        value: 135,
        calssName: 'dropdown-item',
    },
    {label: 'Crunches (4)', value: 136, calssName: 'dropdown-item'},
    {label: 'Push-up (4)', value: 137, calssName: 'dropdown-item'},
    {
        label: 'Internal Rotation Behind Back Abduction Left',
        value: 138,
        calssName: 'dropdown-item',
    },
    {
        label: 'Supine Assisted Shoulder Abduction Left',
        value: 139,
        calssName: 'dropdown-item',
    },
]

const exercisePurposeOptions = [
    {
        label: 'Conditioning',
        value: 'conditioning',
    },
    {
        label: 'Cardio',
        value: 'cardio',
    },
    {
        label: 'Prep',
        value: 'prep',
    },
    {
        label: 'Mobility',
        value: 'mobility',
    },
    {
        label: 'Stability',
        value: 'stability',
    },
    {
        label: 'Cool Down',
        value: 'cool Down',
    },
]

const orientationToAnchor = [
    {
        label: 'Toward',
        value: 'toward',
    },
    {
        label: 'Away',
        value: 'away',
    },
    {
        label: 'Beside',
        value: 'beside',
    },
    {
        label: 'Standing',
        value: 'standing',
    },
]

const bandAngles = [
    {
        label: '0',
        value: 0,
    },
    {
        label: '20',
        value: 20,
    },
    {
        label: '45',
        value: 45,
    },
]

const activityGearXOption = [
    {
        label: 'Active Direct',
        value: 'AD',
    },
    {
        label: 'Active Indirect',
        value: 'AI',
    },
    {
        label: 'Static',
        value: 'ST',
    },
]

const bodyRegion = [
    {
        label: 'Total',
        value: 'total',
    },
    {
        label: 'Lower',
        value: 'lower',
    },
    {
        label: 'Upper',
        value: 'upper',
    },
    {
        label: 'Core',
        value: 'core',
    },
]

const primaryMuscleGroup = [
    {
        label: 'Pectorals',
        value: 'pectorals',
    },
    {
        label: 'Lats',
        value: 'lats',
    },
    {
        label: 'Deltoids',
        value: 'deltoids',
    },
    {
        label: 'Traps',
        value: 'traps',
    },
    {
        label: 'Biceps',
        value: 'biceps',
    },
    {
        label: 'Triceps',
        value: 'triceps',
    },
    {
        label: 'Abdominals',
        value: 'abdominals',
    },
    {
        label: 'Back Extensors',
        value: 'back_extensors',
    },
    {
        label: 'Trunk Rotators',
        value: 'trunk_rotators',
    },
    {
        label: 'Glutes',
        value: 'glutes',
    },
    {
        label: 'Quads',
        value: 'quads',
    },
    {
        label: 'Hamstrings',
        value: 'hamstrings',
    },
    {
        label: 'Hip Flexors',
        value: 'hip_flexors',
    },
    {
        label: 'Hip Adductors',
        value: 'hip_adductors',
    },
    {
        label: 'Hip Abductors',
        value: 'hip_abductors',
    },
    {
        label: 'Calves',
        value: 'calves',
    },
]
const postureType = [
    {
        label: 'Standing',
        value: 'standing',
    },
    {
        label: 'Sitting',
        value: 'sitting',
    },
    {
        label: 'Kneeling',
        value: 'kneeling',
    },
    {
        label: 'Supine',
        value: 'supine',
    },
    {
        label: 'Prone',
        value: 'prone',
    },
    {
        label: 'Side Lying',
        value: 'side_lying',
    },
]
const segmentMotion = [
    {
        label: 'Compound',
        value: 'compound',
    },
    {
        label: 'Isolated',
        value: 'isolated',
    },
    {
        label: 'Comp/Iso',
        value: 'comp/Iso',
    },
]
const primaryPlaneOfMotion = [
    {
        label: 'Sagittal',
        value: 'sagittal',
    },
    {
        label: 'Frontal',
        value: 'frontal',
    },
    {
        label: 'Transverse',
        value: 'transverse',
    },
]
const movementPatterns = [
    {
        label: 'Bilateral',
        value: 'bilateral',
    },
    {
        label: 'Unilateral',
        value: 'unilateral',
    },
    {
        label: 'Alternating',
        value: 'alternating',
    },
    {
        label: 'Reciprocal',
        value: 'reciprocal',
    },
]
const actionType = [
    {
        label: 'Forward Push',
        value: 'forward_push',
    },
    {
        label: 'Upward Push',
        value: 'upward_push',
    },
    {
        label: 'Downward Push',
        value: 'downward_push',
    },
    {
        label: 'Lateral Push',
        value: 'lateral_push',
    },
    {
        label: 'Backward Pull',
        value: 'backward_pull',
    },
    {
        label: 'Upward Pull',
        value: 'upward_pull',
    },
    {
        label: 'Downward Pull',
        value: 'downward_pull',
    },
    {
        label: 'Medial Pull',
        value: 'medial_pull',
    },
    {
        label: 'N/A',
        value: 'none',
    },
]
const contractionFocusOptions = [
    {
        label: 'Concentric',
        value: 'concentric',
    },
    {
        label: 'Eccentric',
        value: 'eccentric',
    },
    {
        label: 'Con/Ecc',
        value: 'con_ecc',
    },
    {
        label: 'Isometric',
        value: 'isometric',
    },
    {
        label: 'Isotonic/Isometric',
        value: 'isotonic_isometric',
    },
]

const difficultyOptions = [
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '4',
        value: 4,
    },
]

const exerciseFilters = [
    {
        label: 'exercise_type',
        display_name: 'equipment',
        children: [
            'normal',
            'rope',
            'bar',
            'gearGo',
            'no_bands',
            'rtf',
            'gearX',
        ],
    },
    {
        label: 'purpose',
        children: [
            'conditioning',
            'resistance',
            'prep',
            'mobility',
            'stability',
            'cool Down',
        ],
    },
    {
        label: 'posture',
        children: [
            'standing',
            'sitting',
            'kneeling',
            'supine',
            'prone',
            'side_lying',
        ],
    },
    {
        label: 'action',
        children: [
            'forward_push',
            'upward_push',
            'downward_push',
            'lateral_push',
            'backward_pull',
            'upward_pull',
            'downward_pull',
            'medial_pull',
        ],
    },
    {
        label: 'segment',
        children: ['compound', 'isolated', 'comp/Iso'],
    },
    {
        label: 'band_angle',
        children: [0, 20, 45],
    },
    {
        label: 'muscle_group',
        children: [
            'pectorals',
            'lats',
            'deltoids',
            'traps',
            'biceps',
            'triceps',
            'abdominals',
            'back_extensors',
            'trunk_rotators',
            'glutes',
            'quads',
            'hamstrings',
            'hip_flexors',
            'hip_adductors',
            'hip_abductors',
            'calves',
        ],
    },
    {
        label: 'primary_plane',
        children: ['sagittal', 'frontal', 'transverse'],
    },
    {
        label: 'anchor_location',
        children: ['center', 'end'],
    },
    {
        label: 'adjuster_position',
        children: ['1', '2', '3'],
    },
    {
        label: 'movement_pattern',
        children: ['bilateral', 'unilateral', 'alternating', 'reciprocal'],
    },
    {
        label: 'contraction_focus',
        children: [
            'concentric',
            'eccentric',
            'con_ecc',
            'isometric',
            'isotonic_isometric',
        ],
    },
    {
        label: 'anchor_orientation',
        children: ['toward', 'away', 'beside', 'standing'],
    },
    {
        label: 'anchor_position_type',
        children: ['low', 'mid', 'up', 'none', 'standing'],
    },
    {
        label: 'grip_type',
        display_name: 'attachment',
        children: ['handles', 'cuff', 'bar', 'loop'],
    },
]

const mandatoryDraftFields = {
    general: ['name', 'display_name'],
}

const mandatoryFields = {
    general: [
        // 'vimeo_id',
        'name',
        'display_name',
        'image',
        'exercise_type',
        'muscle_group',
        'purpose',
        'posture',
        'trainer_gender',
        'difficulty',
        'body_region',
    ],
    per_type: {
        normal: [
            'grip_type',
            'action',
            'segment',
            'primary_plane',
            'anchor_location',
            'movement_pattern',
            'adjuster_position',
            'contraction_focus',
            'anchor_orientation',
            'anchor_position_type',
        ],
        rope: ['met'],
        no_bands: ['met'],
        gearGo: [
            'action',
            'segment',
            'grip_type',
            'band_angle',
            'primary_plane',
            'anchor_location',
            'movement_pattern',
            'contraction_focus',
            'anchor_orientation',
            'anchor_position_type',
        ],
        bar: [
            'bar_mode',
            'anchor_position_type',
            'segment',
            'movement_pattern',
            'action',
            'contraction_focus',
            'primary_plane',
            'anchor_orientation',
            'anchor_location',
        ],
        rtf: ['motion_category', 'user_position'],
    },
}

export {
    barModes,
    gripTypes,
    bandAngles,
    actionType,
    bodyRegion,
    postureType,
    barGripModes,
    segmentMotion,
    exerciseFilters,
    movementPatterns,
    difficultyOptions,
    exerciseEquipment,
    userPositionTypes,
    primaryMuscleGroup,
    caloriesMETOptions,
    orientationToAnchor,
    anchorPositionTypes,
    primaryPlaneOfMotion,
    mandatoryDraftFields,
    MOTION_ANALYSIS_KEYS,
    anchorLocationOptions,
    adjusterPositionTypes,
    exercisePurposeOptions,
    exerciseBodyAreaOptions,
    contractionFocusOptions,
    mandatoryFields,
    activityGearXOption,
}
