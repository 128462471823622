import {Col, FormGroup} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {WatchActivityTypeOptions} from 'constant/workout'
import {useEffect} from 'react'

const WorkoutWatchActivityTypeSelection = ({
    showError,
    watchActivityType,
    setWatchActivityType,
}) => {
    useEffect(() => {
        if (!watchActivityType) {
            setWatchActivityType(WatchActivityTypeOptions[0].value)
        }
    }, [setWatchActivityType, watchActivityType])

    return (
        <Col md={6} className="px-0  pr-md-3">
            <FormGroup>
                <RadioButtonsInput
                    options={WatchActivityTypeOptions}
                    showError={showError}
                    name={'watch_activity_type'}
                    selected={
                        watchActivityType || WatchActivityTypeOptions[0].value
                    }
                    label={'Watch Activity Type'}
                    onChange={(at) => setWatchActivityType(at)}
                />
            </FormGroup>
        </Col>
    )
}

export default WorkoutWatchActivityTypeSelection
