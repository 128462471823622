import {Col, FormGroup} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {WorkoutContentTypeOptions} from 'constant/workout'
import {useEffect} from 'react'

const WorkoutContentTypeSelection = ({
    showError,
    contentType,
    setContentType,
}) => {
    useEffect(() => {
        if (!contentType) {
            setContentType(WorkoutContentTypeOptions[0].value)
        }
    }, [contentType, setContentType])

    return (
        <Col md={6} className="px-0  pr-md-3">
            <FormGroup>
                <RadioButtonsInput
                    options={WorkoutContentTypeOptions}
                    showError={showError}
                    name={'content_type'}
                    selected={contentType || WorkoutContentTypeOptions[0].value}
                    label={'Workout Content Type'}
                    onChange={(ct) => setContentType(ct)}
                />
            </FormGroup>
        </Col>
    )
}

export default WorkoutContentTypeSelection
